import React from "react";
import Page from "../../templates/Page";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import { useValeContext } from "../../../system/ValeSystem";
import { useNavigation } from "@react-navigation/native";

const SettingsPage = () => {
  const vale = useValeContext();
  const navigation = useNavigation();
  return (
    <Page>
      <ValeLaunchButton
        onPress={() => {
          vale?.firebaseAuth.signOut();
          navigation.navigate("landing");
        }}
      >
        Logout
      </ValeLaunchButton>
    </Page>
  );
};

export default SettingsPage;
