import { useMemo } from "react";
import { useSessionContext } from "../../system/SessionProvider";

export const useGetActiveOrders = () => {
  const sessionContext = useSessionContext();
  return useMemo(() => {
    if (!sessionContext?.orderMap) return undefined;
    
    const activeOrders = Array.from(sessionContext?.orderMap.values()).filter(
      (order) =>
        order.orderState === "OPEN" && order.fullfillmentState !== "PREPARED",
    );
    
    return activeOrders || [];
  }, [sessionContext?.orderMap]);
};

export const useGetCompletedOrders = () => {
  const sessionContext = useSessionContext();
  
  return useMemo(() => {
    if (!sessionContext?.orderMap) return undefined;
    const completedOrders = Array.from(sessionContext?.orderMap.values()).filter(
      (order) =>
        order.orderState === "COMPLETED" || order.orderState === "CANCELED" || (order.orderState === "OPEN" && order.fullfillmentState === "PREPARED"),
    );
    completedOrders?.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;
      }
      return 0;
    });
    
    return completedOrders || [];
  }, [sessionContext?.orderMap]);
};
