import { User, signInAnonymously } from "firebase/auth";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useValeContext } from "./ValeSystem";
import { useAuthState } from "react-firebase-hooks/auth";

export const AuthContext = React.createContext<User | null>(null);
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  //const [init, setInit] = useState<boolean>(false);
  const system = useValeContext();
  const [user, loading, error] = useAuthState(system?.firebaseAuth!);

  useEffect(() => {
    console.log("AuthProvider user : ", user);
    //Subscribe to messaging topics
    system?.valeMessaging?.setUser(user?.uid!);
  }, [user]);
  return loading ? null : (
    <AuthContext.Provider value={user!}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
