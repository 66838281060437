import { Paths, UserAssetUploadResponse } from "../vale_common";
import ValeSystem from "./ValeSystem";
import { getEnv } from "./env";
const env = getEnv();

export const userAssetPost = async (
  system: ValeSystem,
  file: File | Blob,
): Promise<string> => {
  const token = await system.firebaseAuth.currentUser?.getIdToken();

  const resp = await system.valeClientOpenapi.api.getAxiosInstance().postForm(
    "/userAsset",
    { image: file },
    {
      baseURL: env.apiUrl.toString(),
      headers: {
        Authorization: "bearer " + token,
      },
    },
  );

  const data = resp.data as UserAssetUploadResponse;
  return data.assetId!;
};

export const tempAssetPost = async (
  system: ValeSystem,
  file: File | Blob,
): Promise<string> => {
  
  const resp = await system.valeClientOpenapi.api.getAxiosInstance().postForm(
    "/tempAsset",
    { image: file },
    {
      baseURL: env.apiUrl.toString(),
    },
  );

  const data = resp.data as Paths.TempAssetUpload.Responses.$200
  return data.assetId!;
};