import { Typography } from "@mui/material";
import { FC, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Components, PaymentCardList } from "../../../vale_common";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./checkout.css"
import { PaymentsInputRefProps } from "./PaymentsInput";
import NewCardModal from "./NewCardModal";
import { DiscoverLogo, MasterCardLogo, VisaLogo } from "../../molecules/bankBrands";


const CARD_WIDTH = 266
const CARD_HEIGHT = 152

const CARD_SCROLL_HEIGHT = "200px"


const carouselSettings: Settings = {
  
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    centerPadding: "0px",
    variableWidth: true,
    // swipeToSlide: true,
    // draggable: true,
  };


type CardSelectorProps = {
    paymentCards?: PaymentCardList;
    selectedCardId?: string
    defaultCardId?: string
    onCardAdded?: (cardId: string) => void
    busy?: boolean
}

const bankLogo = (bank: string) => {
    if (bank?.toLocaleLowerCase() === 'visa') {
        return VisaLogo
    } else if (bank?.toLocaleLowerCase() === 'mastercard') {
      return MasterCardLogo
    } else if (bank?.toLocaleLowerCase() === 'disover') {
      return DiscoverLogo
    }
   return bank
}

type CardTileProps = {
  card: Components.Schemas.PaymentCard;
  //setSelectedCardId: (id: string) => void;
  selectedCardId?: string
  defaultCardId?: string
  index?: number
}

const CardTile: FC<CardTileProps> = (props) => {
  const { index, card, selectedCardId, defaultCardId, ...otherProps } = props;

  
  let hash = 0;
  for (let i = 0; i < card.id?.length!; i++) {
      hash = card.id!.charCodeAt(i) + ((hash << 5) - hash);
  }

  const backgroundIndex = ( Math.abs(hash) % 5) + 1

  const boxShadow = selectedCardId == card.id ? "0px 10px 20px purple" : undefined
  const defaultEndorsment = useMemo (() => {
            const isDefault = defaultCardId === card.id
            console.log("defaultCardId, card.id", defaultCardId, card.id)
            return isDefault ? <div style={{
            position: "absolute",
            height: "24px",
            width: "75px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
            marginLeft: "16px",
            backgroundColor: "#7C50FF",
            top: "-24px"
          }}>
          <Typography style={{
             color: 'white',
             fontSize: 12,
             fontWeight: '600',
             margin: "auto",
           }}>Default 👇</Typography></div> : null
  }, [defaultCardId, card.id])
  return (
    <div {...otherProps}>
      <div style={{height: CARD_SCROLL_HEIGHT, width: "300px", justifyContent: "center", alignItems: "center", display: "flex"}}>
      <div key={card.id}
                    style={{
                      position: "relative",
                      width: CARD_WIDTH,
                      height: CARD_HEIGHT,
                      borderRadius: 7,
                      backgroundImage: "/images/card_placeholder_1.jpg",
                      }}>
                      
            {defaultEndorsment}
              
            <div style={{boxShadow, position: "relative",borderRadius: 7, overflow: "hidden", width: CARD_WIDTH, height: CARD_HEIGHT}}> 
              <div style={{position: 'absolute', top:0, left:0, right: 0, bottom: 0, zIndex: -1, objectFit: 'cover'}}>
                <img style={{objectFit: 'cover', width: "100%", height: "100%"}} src={`/card_templates/${backgroundIndex}.gif`}/>
              </div>
              <div style={{position: 'absolute', right: "16px", top: "16px", color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500'}}>{card.cardType}</div>
              <div style={{position: 'absolute', right: "16px", bottom: "16px"}}>
                  {bankLogo(card.cardBrand!)}
              </div>
              <div style={{position: 'absolute', left: "32px", bottom: "32px", }}>
                  <Typography style={{
                      color: '#2C2C2C',
                      fontSize: 16,
                      fontWeight: '500',
                  }}>•••• {card.last4}
                  </Typography>
              </div> 
          </div> 
        </div>
      </div>
    </div>
  )
}

const CardSelector = forwardRef<PaymentsInputRefProps, CardSelectorProps> ((props, ref) => {
  const [newCardModalOpen, setNewCardModalOpen] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<string>()

  useImperativeHandle(ref, ()=> ({
      processInput: async () => {
         return selectedCardId
      }
  }), [selectedCardId])
      
    const cardSelector = useMemo( ()=>{
      if ( selectedCardId === undefined ) {
        if ( props.defaultCardId && props.paymentCards?.find(card => card.id === props.defaultCardId) !== undefined) {
            setSelectedCardId(props.defaultCardId)
        } else {
          const updatedSelectedCardId = props.paymentCards?.[0].id
          setSelectedCardId(updatedSelectedCardId)
        }
      }

      if (!props.paymentCards || props.paymentCards?.length === 0) {
        return null
      } else if (props.paymentCards?.length === 1) {
        //This is a work a round hack for the crappy slider library. It seems all slider libraries suck hard.
        const singleCard = props.paymentCards[0]
        return <div style={{display: "flex", justifyContent: "center"}}><CardTile key={singleCard.id} card={singleCard} selectedCardId={selectedCardId} defaultCardId={props.defaultCardId}/></div>
      }


      const cards = props.paymentCards?.map((card) => (<CardTile key={card.id} card={card} selectedCardId={selectedCardId} defaultCardId={props.defaultCardId}/>))

      const initialSlide = props.paymentCards?.findIndex(card => card.id === props.defaultCardId) || 0

      return <Slider  initialSlide={initialSlide}
      afterChange={(index)=>{
        const cardId = props.paymentCards?.[index].id
        setSelectedCardId(cardId)
        }} {...carouselSettings}>
        {cards}
      </Slider>
    } , [props.paymentCards, selectedCardId, props.defaultCardId])

    return <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "32px", rowGap: "24px"}}>
      <div style={{width: "100%", height: CARD_SCROLL_HEIGHT}}>
        {cardSelector}  
      </div>
   
      <div style={{display: "flex", flexDirection: "row", columnGap: "8px"}} onClick={()=> {if ( !props?.busy) setNewCardModalOpen(true)}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.6341" cy="11.6341" r="10.6341" fill="#E73870" stroke="#2C2C2C" strokeWidth="2"/>
          <rect x="10.4707" y="6.39877" width="2.32683" height="10.4707" fill="#2C2C2C"/>
          <rect x="16.8691" y="10.4707" width="2.32683" height="10.4707" transform="rotate(90 16.8691 10.4707)" fill="#2C2C2C"/>
        </svg>
        <Typography> ADD NEW CARD</Typography>
      </div>
      <NewCardModal
        onCloseButtonClicked={(() => setNewCardModalOpen(false))}
        onCardAdded={props.onCardAdded}
        open={newCardModalOpen}
        />
      </div>
})


export default CardSelector