import { Theme } from "@react-navigation/native";
import valePaperTheme from "../components/consts/theme";

const navTheme: Theme = {
  dark: true,
  colors: {
    primary: valePaperTheme.colors.primary,
    background: "transparent", //"rgb(1, 1, 1)",
    card: "rgb(18, 18, 18)",
    text: valePaperTheme.colors.primary,
    border: "rgb(39, 39, 41)",
    notification: "rgb(255, 69, 58)",
  },
};

export default navTheme;
