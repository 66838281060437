import React, { useMemo } from "react";
import Page from "../../templates/Page";
import { useGoToHome, useGoToStartOrder } from "./landing_page_utils";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import LandingPageClosed from "./LandingPageClosed";
import PagesParamList from "../pages_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNoSessionContext } from "../../../system/NoSessionProvider";
import { getStoreStatus } from "../../../system/menu/menuUtils";
import { Box, Link, Typography } from "@mui/material";
import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import Footer from "../../molecules/footer";
import { useSessionContext } from "../../../system/SessionProvider";


type Props = NativeStackScreenProps<PagesParamList, "landing">;
const LandingPage: React.FC<Props> = () => {
  const noSessionInfo = useNoSessionContext();
  const session = useSessionContext();
  const goToStartOrder = useGoToStartOrder();
  const goToHome = useGoToHome();

  const storeStatusInfo = useMemo(() => {
    return getStoreStatus(noSessionInfo?.locations);
  }, [noSessionInfo?.locations]);

  const login = useMemo(()=> {
    return session?.userData?.userRecord?.uid ? null : <><Typography style={{
      color: '#2C2C2C',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: '600'
    }}>Back Again,</Typography>
    
    <div style={{
      color: 'white',
      fontSize: 14,
      fontFamily: 'Inter',
      fontWeight: 600,
      textDecoration: 'underline',
      wordWrap: 'break-word'
    }}
    onClick={goToHome}>SIGN-IN {'>'}</div></>
  }, [session?.userData?.userRecord?.uid])

  if (!storeStatusInfo?.storeStatus) {
    console.warn("Store status not found");
    return null;
  } else {
    console.info("Store status found");
  } 

const isClosed = storeStatusInfo.storeStatus !== "OPEN";

  const subTitle = isClosed ? "Elevated cold coffee, matcha, and tea drinks made in the heart of Venice." : "Ordering is fast. Can you handle it? 💪"
  const ctaText = isClosed ? "VIEW MENU" : "ORDER NOW";
  let numberOfItems = 2;
  let items = [];
  for (let i = 0; i < numberOfItems; i++) {
      items.push(<Box key={i} width={50} height={50} style={{backgroundColor: i % 2 == 0 ? "red":"green"}}>{i}</Box>);
  }

  return (
    <Page>
      <div style={{overflow: 'hidden', height: 347, width:'100%', display: 'flex', justifyContent: 'center'}}>
        <video id="heroVideo" height="347" autoPlay playsInline loop muted poster="https://storage.googleapis.com/vale_web_assets_dev/hero_still.jpg">
          <source src="https://storage.googleapis.com/vale_web_assets_dev/hero.mp4" type="video/mp4"/>    
        </video>
      </div>
      <PageTitle>WELCOME<br/>TO YOUR THIRSTY SIDE</PageTitle>
      <PageSubtitle style={{marginTop : 16}}>{subTitle}</PageSubtitle>
        <Box
          style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 100,
          }}
        >
          <br/>
          <ValeLaunchButton
            width={155}
            onPress={goToStartOrder}
            fontStyle={{fontSize:18}}
            withArrow={true}
          >
            {ctaText}
          </ValeLaunchButton>
          <br/>
          {login}
      </Box>
      
      <Footer />
    </Page>
  );
};

export default LandingPage;