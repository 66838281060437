import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PageTitle } from '../../atoms/TextStyles';
import ScrollingText from '../../molecules/ScrollingText';
import ValeLaunchButton from '../../molecules/ValeLaunchButton';
import GoAgainComp from '../home/GoAgainComp';
import Footer from '../../molecules/footer';

const ContactSuccess = () => {

    function goToInstagram() {
        window.open('https://www.instagram.com/valegonow', '_blank');
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '50px'
                }}
                >
                <img
                    src="./match_bounce.gif"
                    alt="Centered"
                    style={{
                    width: '10%',
                    height: '10%',
                    objectFit: 'contain',
                    }}
                />
            </Box>

            <PageTitle style={{ maxWidth: 304, width: 304 }}>THANK YOU!</PageTitle>
            <Typography style={{ margin: "20px 0px", marginBottom: "50px", 
                lineHeight: "1.2em", textAlign: "center", color: "white", 
                fontWeight: "500" }}>
                    We'll get back to you ASAP!
            </Typography>

            <Box 
                width={"100%"}
                height={71}
                style={{backgroundColor:"#FF00B8", marginTop: "200px"}}
            >

                <ScrollingText text="👇👇👇 WHILE YOU'RE WAITING...👇👇👇 WHILE YOU'RE WAITING..." />
            </Box>
        
            <Box
                width="85%"
                border="2px solid black"
                style={{margin: "auto", marginTop: 40}}
            >


                <video id="heroVideo" width={"100%"} autoPlay playsInline loop muted poster="https://storage.googleapis.com/vale_web_assets_dev/hero_still.jpg">
                <source src="https://storage.googleapis.com/vale_scratch_prod/trucktimelapse.mp4" type="video/mp4"/>    
                </video>

                <Typography style={{textAlign: "center", fontFamily:"BN Dime Display", fontSize:26, color: "#2C2C2C"}}>KEEP UP WITH VALE</Typography>
                <Typography style={{marginBottom: 20, textAlign: "center", fontFamily:"Inter",color:"white", fontWeight:500 }}>See what's new, where we'll be, and<br/>all the fun stuff in between 😎</Typography>
            
                <center><ValeLaunchButton
                width={165}
                height={50}
                fontStyle={{fontSize:18}}
                onPress={goToInstagram}
                >
                FOLLOW US
                </ValeLaunchButton></center>
                
                <div style={{height: 30, width: "100%"}}>&nbsp;</div>

            </Box>
            
            <GoAgainComp />
          
            <Footer/>
                
        </>
    );
};

export default ContactSuccess;