import "./selection.css"
import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import OrderButton from "./OrderButton";
import SelectionItemView from "./SelectionItemView";
import { Money } from "../../../../../vale_common";

export type Props = {
  onPress: (id?: string) => Promise<boolean>;
  notAvailable?: boolean;
  discountPercentage?: number;
  name: string;
  iconUrl?: string;
  description?: string;
  price?: Money;
  ordered?: boolean;
  onStoreClosed?: () => void;
  subSelection?: {
    options?: {
      uid: string;
      name: string;
    }[];
  };
};

const getPriceStr = (price: Money | undefined) => {
  if (price?.amount === undefined || null) return "";
  if ( price?.amount === 0 ) return "FREE!";
  return (Number(price?.amount) / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: price?.currency,
  });
};


const SelectionItem = ({
  iconUrl,
  name,
  description,
  price,
  onPress,
  notAvailable,
  ordered,
  onStoreClosed
}: Props) => {
  
  const priceStr = getPriceStr(price);

  const onPressView = async () => {
    return onPress();
  }

  return <SelectionItemView
    onStoreClosed={onStoreClosed}
    onPress={onPressView}
    notAvailable={notAvailable}
    name={name}
    description={description}
    iconUrl={iconUrl}
    //discountPriceStr={discountPriceStr}
    priceStr={priceStr}/>
};

export default SelectionItem;
