export const splitNames = (userName?: string) => {

    let firstName = ""
    let lastName = ""

    const names = userName?.split(' ')
    if ( names ) {
        if ( names.length == 1 ) {
            firstName = names[0]
        } else if ( names.length > 1 ) {
            firstName = names.slice(0, firstName.length - 1).join(' ')
            lastName = names[names.length - 1]
        }
    }
  
    return {
        firstName,
        lastName
    }

}