import { FC, forwardRef, useEffect, useMemo } from "react"
import PaymentsInput, { PaymentsInputRefProps } from "./PaymentsInput";
import { useSessionContext } from "../../../system/SessionProvider";
import CardSelector from "./CardSelector";

export type CardPaymemntSelectionProps = {
    onCardAdded: (paymentId: string) => void;
    busy?: boolean;
}

const CardPaymemntSelection = forwardRef<PaymentsInputRefProps, CardPaymemntSelectionProps> ((props, ref) => {
    const session = useSessionContext();
    
    const defaultCardId = useMemo(() => {
        return session?.userData?.userDoc?.defaultPaymentMethodId
    }, [session?.userData?.userDoc?.defaultPaymentMethodId])

    if ( !session?.loaded) return null

    const hasSavedCards = session?.paymentCards?.length && session?.paymentCards?.length > 0
    return hasSavedCards ? <CardSelector busy={props.busy} onCardAdded={props.onCardAdded} ref={ref} defaultCardId={defaultCardId} paymentCards={session?.paymentCards}/> : <PaymentsInput ref={ref}/>
})

export default CardPaymemntSelection