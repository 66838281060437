import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import React, { useCallback } from 'react';
import { ReactSVG } from "react-svg";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Container, Typography } from '@mui/material';
import { useCartContext } from '../../system/CartProvider';
import { useValeStackNavigation } from '../../nav/nav_utils';

export const HEADER_HEIGHT = 64
const DROP_SHADOW = 17
const ValeHeader: React.FC<(NativeStackHeaderProps & {onMenuOpen: () => void})> = (props) => {
    const cart = useCartContext();
    const navigation = useValeStackNavigation();
    
    const onLanding = useCallback(()=>{
        props.navigation.navigate("landing");
    }, [])

    const onCart = useCallback(()=>{
        props.navigation.navigate("order", {screen: "cart"});
    }, [])
    return (
        
    <Box style={{position: "fixed", boxShadow: '0 5px 10px 3px rgba(0, 0, 255, 0.5)', backgroundColor: '#E30A8F'}} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} height={HEADER_HEIGHT} id="valls">
        <Box  onClick={props.onMenuOpen} display={'flex'} justifyContent={'center'} alignItems={'center'} width={HEADER_HEIGHT} height={HEADER_HEIGHT}><MenuIcon fontSize={'large'}/></Box>
        <div style={{marginTop: '40px'}} onClick={onLanding}><ReactSVG src="/logo_black.svg"/></div>
        <div style={{display: 'flex', justifyContent: "center", alignItems:"center", width:HEADER_HEIGHT, height: HEADER_HEIGHT}} onClick={onCart}>
            <ReactSVG src="/shopping_tote.svg" />
            <div style={{ position: "absolute",
                textAlign: "center",
                top: 27,
                }}><Typography style={{color: "white", fontSize: 12, fontFamily: "Inter", fontWeight: 700}}>{cart?.items?.length || "0"}</Typography></div>
        </div>
        </Box>
    
    );
}

export default ValeHeader;