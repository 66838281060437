import React from 'react';

interface ScrollingTextProps {
  text: string;
}

const ScrollingText: React.FC<ScrollingTextProps> = ({ text }) => {
  return (
    <div style={containerStyle}>
      <div style={scrollingContainerStyle}>
        <div style={scrollingTextStyle}>
          {text} {text} {text}
        </div>
      </div>
      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  overflow: "hidden",
  fontFamily: "BN Dime Display",
  fontSize: 20,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%', // Ensure the container takes the full width
  whiteSpace: 'nowrap',
  position: 'relative',
  color: "#2C2C2C",
};

const scrollingContainerStyle: React.CSSProperties = {
  display: 'flex',
  width: '200%', // Ensure the container is twice as wide to accommodate the duplicated text
};

const scrollingTextStyle: React.CSSProperties = {
  display: 'flex',
  animation: 'scroll 15s linear infinite',
};

export default ScrollingText;
