import { User, signInAnonymously } from "firebase/auth";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useValeContext } from "./ValeSystem";
import { AxiosResponse } from "axios";
import { processCachedReferralCode } from "./referrals";
import { CreateOrderRequest, CreateOrderResponse, CreatePaymemtCardResponses, CreateReferralCodeRequest, LoyalityInfo, OrderList, OrderMap, PaymentCardList, PaymentList, UpdateUserRequest, UpdateUserResponse, UserData, UserDoc, UserRecord } from "../vale_common";

export type ReferralCodeTypes = CreateReferralCodeRequest['referralCodeType']

export type SessionInfo = {
  loaded?: boolean;
  userData?: UserData;
  orderMap?: OrderMap;
  paymentCards?: PaymentCardList;
  //loyalityInfo?: LoyalityInfo;

  refresh?: () => Promise<void>;

  refreshOrderById?: (orderId: string) => Promise<void>;

  paymentAddCard?: (
    token: string,
    isDefault?: boolean,
  ) => Promise<AxiosResponse<CreatePaymemtCardResponses, any> | undefined>;

  getReferralCode?: (type: ReferralCodeTypes) => Promise<string | undefined>

  createOrder?: (
    data: CreateOrderRequest,
  ) => Promise<AxiosResponse<CreateOrderResponse, any> | undefined>;

  updateUserName?: (
    data: UpdateUserRequest,
  ) => Promise<AxiosResponse<UpdateUserResponse, any> | undefined>;
};

export const SessionContext = React.createContext<SessionInfo | null>(null);
export const useSessionContext = () => useContext(SessionContext);

const SessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const system = useValeContext();

  //const [loyalityInfo, setLoyalityInfo ] = useState<LoyalityInfo | undefined>(undefined);
  const [orderMap, setOrderMap] = useState<OrderMap | undefined>(undefined);
  const [paymentCards, setPaymentCards] = useState<PaymentCardList | undefined>(undefined)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [userData, setUserData] = useState<UserData>()

  const refresh = useCallback(async () => {
    const userRecord = system?.firebaseAuth?.currentUser;
    const userId = userRecord?.uid;
    if (!userId) {
      console.warn("no user id during session refresh");
      return;
    }

    const sessionInfo = await system?.valeClientOpenapi.getSessionInfo();

    setLoaded(true)

    const userDoc = sessionInfo?.data.userData?.userDoc//results[0] as UserDoc;
    const orders = sessionInfo?.data.orders//results[1] as OrderList;
    const paymentCards = sessionInfo?.data.paymentCards//results[2] as PaymentList;

    // const loyalityInfo: LoyalityInfo = {
    //   bonkerPoints: userDoc?.squareLoyaltyBalance,
    //   bonkerProgramId: userDoc?.squareLoyalityProgramId,
    // };

    const orderMap = new Map(orders?.map((order) => [order.id!, order]));

    const userData = {
      userDoc: userDoc,
      userRecord: userRecord as UserRecord,
    };

    setUserData(userData)
    //setLoyalityInfo(loyalityInfo);
    setPaymentCards(paymentCards)
    setOrderMap(orderMap)
    setLoaded(true)
  }, []);

  const refreshUserInfo = useCallback(async () => {
    const userRecord = system?.firebaseAuth?.currentUser;
    const userId = userRecord?.uid;

    if (!userId) {
      console.warn("no user id during session refresh");
      return;
    }

    const userDocResp = await system?.valeClientOpenapi.getUser();
    const userDoc = userDocResp?.data.userData

    const userData = {
      userDoc: userDoc,
      userRecord: userRecord as UserRecord,
    };

    setUserData((data) => ({...data, userDoc }))
  }, []);

  const paymentAddCard = useCallback(async (paymentId: string, setDefault?: boolean) => {
    const resp = await system?.valeClientOpenapi.createPaymemtCard(null, {
      paymentId,
      setDefault
    });
    
    setPaymentCards(resp?.data.paymentCards)

    if ( setDefault ) {
      //Update user doc with default payment method, or we can just refresh the user doc from the server
      setUserData(userData => {
        const userDoc = { ...userData?.userDoc, defaultPaymentMethodId: resp?.data.newCardId}
        return { userDoc, userRecord: userData?.userRecord }
      })
    }
    return resp;
  }, []);
  
  const updateUserName = useCallback(async (data: UpdateUserRequest) => {
    const resp = await system?.valeClientOpenapi.updateUser(null, data);
    await system?.firebaseAuth.currentUser?.reload();

    setUserData(oldUserData => ({      userDoc: oldUserData?.userDoc,
      userRecord: system?.firebaseAuth.currentUser as UserRecord,}))
    return resp;
  }, []);

  const getReferralCode = useCallback(async (referralCodeType: ReferralCodeTypes) => {
    const resp = await system?.valeClientOpenapi.createReferralCode(null, {referralCodeType});
    return resp?.data.referralCode
  }, [])

  const createOrder = useCallback(async (data: CreateOrderRequest) => {
    const resp = await system?.valeClientOpenapi.createOrder(null, data);
    if (data.loyalityProgramId) {
      //order was created with loyality program. Refresh loyality info
      refreshUserInfo();
    }

    const newOrder = resp?.data.order

    setOrderMap( oldOrderMap => {
      const orderMap = new Map(oldOrderMap);
      orderMap.set(newOrder?.id!, newOrder!);
      return orderMap
    })

    return resp;
  }, []);

  const refreshOrderById = useCallback(async (orderId: string) => {
    const resp = await system?.valeClientOpenapi.getOrderById({ orderId });

    const updatedOrder = resp?.data.order

    setOrderMap( oldOrderMap => {
      const orderMap = new Map(oldOrderMap);
      orderMap.set(updatedOrder?.id!, updatedOrder!);
      return orderMap
    })

  }, []);

  useEffect(() => {
    //First time add callbacks
    system?.firebaseAuth.onAuthStateChanged(async (user) => {
      //Handle Auth Status Changes
      if (user) {
        //@ts-ignore
        gtag('set', {
          'user_id': user.uid
        });
        processCachedReferralCode(system);
        refresh();
  
      } else {
        //clear out user data
        setOrderMap(undefined)
        setUserData(undefined)
        setPaymentCards(undefined)
        //setLoyalityInfo(undefined)
      }
    });

    return system?.valeMessaging?.addValeUserDataEventListener(async (event) => {
      try {
        if (event.action === "update" && ( event.entity === "order" || event.entity === "order_fulfillment")) {
          const orderId = event.id;
          if (system?.firebaseAuth.currentUser) {
            refreshOrderById(orderId);
          }
        }
      } catch (e) {
        console.warn(`OrderProvider exception : `, e?.toString());
      }
    });
  }, []);
  
  const info = { refresh,
    getReferralCode,
    createOrder,
    refreshOrderById,
    paymentAddCard,
    updateUserName,
    orderMap,
    paymentCards,
    //loyalityInfo,
    userData,
    loaded}
  return !info ? null : (
    <SessionContext.Provider value={info}>{children}</SessionContext.Provider>
  );
};

export default SessionProvider;
