import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { OrderStackParamList } from "../../../components/pages/order/order_param_list";
import SelectionPage from "../../../components/pages/home/menu/selection/SelectionPage";
import CartPage from "../../../components/pages/home/menu/selection/CartPage";
import ReviewOrderPage from "../../../components/pages/home/menu/selection/ReviewOrderPage";
import CartEditNamePage from "../../../components/pages/home/menu/selection/CartEditNamePage";

const OrderStackNavigator = createNativeStackNavigator<OrderStackParamList>();

export const OrderRoutes = () => (
  <OrderStackNavigator.Navigator screenOptions={{ headerShown: false }}>
    <OrderStackNavigator.Screen name="selection" component={SelectionPage} />
    <OrderStackNavigator.Screen name="cart" component={CartPage} />
    <OrderStackNavigator.Screen name="cart_item_edit" component={CartEditNamePage} />
    <OrderStackNavigator.Screen name="review" component={ReviewOrderPage} />    
  </OrderStackNavigator.Navigator>
);
