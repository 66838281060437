import { ReferralCodeType, ReferralInfo } from "../vale_common";
import ValeSystem from "./ValeSystem";

const REFERRAL_CACHE_CODE = "referralCode";

export async function processUserReferralCode (referralCode: string | null | undefined, referralType: ReferralCodeType | null | undefined, vale: ValeSystem) {
    if ( !referralCode || !referralType ) {
        console.error("No referral code or type");
        return;
    }
    const referralInfo: ReferralInfo = {referralCode, referralType}

    if ( vale.firebaseAuth.currentUser?.uid ) {
        //Upload
        const resp = await vale.valeClientOpenapi.registerReferral(null, referralInfo);
        if ( resp.status == 200  ) {   
            return;
        }

        //registration failed. Cache it
    } 

    //Store in local storage for later
    localStorage.setItem(REFERRAL_CACHE_CODE, JSON.stringify(referralInfo));

}

export async function processCachedReferralCode(vale: ValeSystem) {
    const referralInfoStr = localStorage.getItem(REFERRAL_CACHE_CODE);
    if ( !referralInfoStr ) {
        return
    }

    const referralInfo = JSON.parse(referralInfoStr) as ReferralInfo;
    if ( !referralInfo?.referralCode || !referralInfo?.referralType ) {
        console.warn("Invalid referral info");
        localStorage.removeItem(REFERRAL_CACHE_CODE);
        return;
    }

    const resp = await vale.valeClientOpenapi.registerReferral(null, referralInfo);
    if ( resp.status == 200  ) {
        localStorage.removeItem(REFERRAL_CACHE_CODE);
        return;
    }

    //Keep Caching it
}