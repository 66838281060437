import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CheckoutStackParamList } from "../../../components/pages/checkout/checkout_param_list";
import PaymentSelectionPage from "../../../components/pages/checkout/PaymentSelectionPage";
import CheckoutAuthScreen from "../../../components/pages/checkout/CheckoutAuthScreen";

const CheckoutStackNavigator =
  createNativeStackNavigator<CheckoutStackParamList>();

export const CheckoutRoutes = () => (
  <CheckoutStackNavigator.Navigator screenOptions={{ headerShown: false }}>
    <CheckoutStackNavigator.Screen
      name="phoneNumberEntry"
      component={CheckoutAuthScreen}
    />
    <CheckoutStackNavigator.Screen
      name="paymentSelection"
      component={PaymentSelectionPage}
    />
  </CheckoutStackNavigator.Navigator>
);
