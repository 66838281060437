import '@fontsource/bitter';
import React, { useEffect, useState } from "react";
import ValeSystem, { ValeContext } from "./system/ValeSystem";
import { Provider as PaperProvider } from "react-native-paper";
import valePaperTheme, { valeMuiTheme } from "./components/consts/theme";
import FontFaceObserver from "fontfaceobserver";
import AuthProvider from "./system/AuthProvider";
import PageRoutes from "./nav/routes/pages_routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoSessionProvider from "./system/NoSessionProvider";
import SessionProvider from "./system/SessionProvider";
import ValeBackground from "./components/molecules/ValeBackground";
import { ThemeProvider } from "@mui/material";
import CartProvider from './system/CartProvider';

export const background = require("./assets/svg/background.svg");

const App: React.FC = () => {
  
  const [fontReady, setFontReady] = useState(true);
  const [vale, setVale] = useState<ValeSystem | undefined>();
  useEffect(() => {
    //We need to load the font before we can render anything.
    //Otherwise, the text will render with the wrong font.
    //It's a race condition thing.
    var font = new FontFaceObserver("BN Dime Display");
    font.load().then(() => {
      setFontReady(true);
    }).catch(() => {
      console.warn("font ready timed out")
      setFontReady(true);
    });

    const valeSystem = new ValeSystem();
    valeSystem.init().then(() => {
      setVale(valeSystem);
    });
  }, []);

  const stack =
    vale && fontReady ? (
      <PaperProvider theme={valePaperTheme}>
        <ThemeProvider theme={valeMuiTheme}>
          <ToastContainer />
          <ValeContext.Provider value={vale}>
            <NoSessionProvider>
              <AuthProvider>
                <SessionProvider>
                  <CartProvider>
                    <PageRoutes />
                  </CartProvider>
                </SessionProvider>
              </AuthProvider>
            </NoSessionProvider>
          </ValeContext.Provider>
        </ThemeProvider>
      </PaperProvider>
    ) : null;

  return (
    <>
      <ValeBackground />
      {stack}
    </>
  );
};

export default App;
