import { Drawer, Modal } from "@mui/material";
import PromptPage, { promptPageModalComProps } from "../../../modal/PromptPage";
import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { PageSubtitle, PageTitle } from "../../../../atoms/TextStyles";
import ValeTextInput from "../../../../molecules/ValeTextInput";
import { useSessionContext } from "../../../../../system/SessionProvider";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import { useCartContext } from "../../../../../system/CartProvider";


// inputRef={inputRef} defaultValue={props.innerProps?.defaultName}

type InnerProps = {
    onComplete?: (name: string | undefined) => void;
    defaultName?: string
}

type DrinkNameEntryProps = {
    forwardedRef?: ForwardedRef<DrinkNameEntryProps>;
    onCloseButtonClicked: () => void;
    innerProps?: InnerProps
}

export const DrinkNameEntry: React.FC<DrinkNameEntryProps> = (props) => {
    const [name, setName] = useState<string | undefined>();
    const inputRef = useRef<HTMLInputElement>(null);
    const onPress = useCallback(() => {
        props.innerProps?.onComplete?.(inputRef?.current?.value)
    }, [inputRef?.current, props.innerProps?.onComplete]);

    return (
        <PromptPage onCloseButtonClicked={props.onCloseButtonClicked}>
            <PageTitle>WHO’S DRINKIN’ THIS?</PageTitle>
            <div style={{display: "flex", justifyContent: "center", marginTop: 16}}>
                <ValeTextInput onValueChange={setName} inputRef={inputRef} defaultValue={props.innerProps?.defaultName} style={{maxWidth:302, width: "100%",}} placeHolder="Name" />
            </div>
            <div style={{display: "flex", justifyContent: "center", marginTop: 16}}>
            <ValeLaunchButton fontStyle={{fontSize: 18, fontWeight: 700}} disabled={!name} onPress={onPress}>CONTINUE</ValeLaunchButton>
            </div>
        </PromptPage>
    );
};

const DrinkNameEntryRef = React.forwardRef<any, DrinkNameEntryProps>((props, ref) => <DrinkNameEntry {...props} forwardedRef={ref} />);

export type DrinkNameEntryModalProps = {}
export type DrinkNameEntryModalHandle = {
    getName: (tempItemId: string | undefined, defaultName?: string) => Promise<string|undefined>;
}

export const DrinkNameEntryModal = forwardRef<DrinkNameEntryModalHandle,DrinkNameEntryModalProps>((props, ref) => {
    const [open, setOpen] = useState(false);
    const [innerProps, setInnerProps] = useState<InnerProps>();
    
    useImperativeHandle(ref, () => ({
        getName: async (tempItemId: string | undefined, defaultName?: string): Promise<string|undefined> => {
            return new Promise((resolve, reject) => {
                if ( !tempItemId ) {
                    console.warn("No temp item id provided to DrinkNameEntryModal")
                }
                const innerProps: InnerProps = {
                        onComplete: (value: string | undefined) => {
                            setOpen(false)
                            resolve(value)
                            setInnerProps(undefined)
                        },
                        defaultName
                }
                setInnerProps(innerProps)
           
                setOpen(true)
            });
        }
      }));

    return <Modal open={open} componentsProps={promptPageModalComProps}>
        <DrinkNameEntryRef innerProps={innerProps} onCloseButtonClicked={()=>{
            
            if ( innerProps?.onComplete ) {
                innerProps?.onComplete?.(undefined);
            } else {
                setOpen(false);
            }
        }}/>
    </Modal>
})

