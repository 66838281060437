import React from "react";
import Page from "../../../templates/Page";
import Footer from '../../../molecules/footer';

const AccountToggle: React.FC = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    imageContainer: {
      position: 'relative',
      display: 'inline-block',
      width: '100%',
    },
    heroImage: {
      width: '100%',
      height: 'auto',
    },
    accountLabel: {
      position: 'absolute',
      bottom: '3px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#E6007E',
      color: 'white',
      fontWeight: 'bold',
      padding: '10px 20px',
      borderRadius: '5px',
      fontSize: '24px',
      textAlign: 'center',
    },
  };

  return (
    <Page>
      <div style={styles.imageContainer}>
        <img
          src="./images/youraccount-hero 1.jpg"
          alt="Your Account Hero"
          style={styles.heroImage}
        />
        <div style={styles.accountLabel}>
          YOUR ACCOUNT
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default AccountToggle;
