import React, { InputHTMLAttributes } from "react";
import "./molecules.css";


type ValeTextInputProps = {
  style?: React.CSSProperties;
  placeHolder?: string
  defaultValue?: string
  inputRef?: React.RefObject<HTMLInputElement>
  onValueChange: (value: string | undefined) => void
}

const ValeTextInput: React.FC<ValeTextInputProps> = (props) => {
  return (
    <div style={{position: 'relative', display: 'flex', justifyContent: 'center', ...props.style}}>
      <input
             style={{
              width: "100%",
              maxWidth: 339,
              height: 50,
              fontSize: 18,
              fontFamily: "Inter",
              fontWeight: "500",
              borderColor: "#2C2C2C",
              borderRadius: "4px",
              borderStyle: "solid",
              borderWidth: "2px",
              outlineColor: "#FF00B8",
              color: "#FF00B8",
              
            }}
            defaultValue={props.defaultValue}
            placeholder={props.placeHolder}
            ref={props.inputRef}
            onChange={(e) => props.onValueChange(e.target.value)}
      />
    </div>
  );
};

export default ValeTextInput;
