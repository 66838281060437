import React, { useMemo } from "react";
import Page from "../../templates/Page";
import { useGoToHome, useGoToStartOrder } from "../landing/landing_page_utils";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import LandingPageClosed from "../landing/LandingPageClosed";
import PagesParamList from "../pages_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNoSessionContext } from "../../../system/NoSessionProvider";
import { getStoreStatus } from "../../../system/menu/menuUtils";
import { Box, Link, Typography } from "@mui/material";
import { PageTitle } from "../../atoms/TextStyles";
import Footer from '../../molecules/footer';


const AboutUsPage = () => {
  
  const goToHome = useGoToHome();

  
    return (
      <Page>
        <div>
          <img src="https://storage.googleapis.com/vale_scratch_prod/about/abouthero.jpg" width="100%"/>
        </div>
        
        <PageTitle style={{maxWidth:304,width:304}}>IT'S NEVER TOO LATE TO START OVER.</PageTitle>

        
        
        <div style={{color:"white",
            fontSize: 14, 
            textAlign:"center",
            margin: "auto",
            padding: 40
            }}>

            <Typography style={{fontWeight:"600"}}>After two decades in tech, I found myself<br/>searching for something more. Planning to leave the corporate tech bubble, I founded Elda, a San Francisco bar in 2019, which ignited in me a<br/>sense of adventure and joy. But COVID<br/>intervened, prompting its closure.<br/><br/>Needing an escape & more sunshine, I ventured to Venice, where conversations didn't begin or end with talk of funding rounds, IPOs and stock performance, I found the perfect place to reset and rediscover my purpose. I met people like me who want to live life to its fullest, do hard sh*t and explore a life outside of corporate boundaries.<br/><br/>Motivated by failure and an obsession with cold coffee drinks it became clear my opportunity was to create a coffee concept based on better flavors, less annoyance and more fun. This is where the VALE story begins and we're happy you're here and part of our journey.</Typography>

            <Typography style={{color:"black", fontWeight:"600", fontSize:20, marginTop:25}}>Jay De Natale</Typography>
            <Typography style={{color:"white", fontWeight:"600", fontSize:14}}>Founder</Typography>
        </div>
        
        <Box width="90%" height={139} style={{position: "relative", justifyContent: 'center', backgroundColor:"#FF00B8", margin: "auto", marginBottom: 50, marginTop: 100, border:"2px solid #2C2C2C", borderRadius: 7}}>

            <img src="https://storage.googleapis.com/vale_scratch_prod/about/contactus.png" style={{width: "60%", position: "absolute", top:-65, margin: "auto", left: 0, right: 0, textAlign:"center"}}/>

            <Typography style={{color: "white", textAlign:"center", paddingTop:40, fontWeight:"600", fontSize:16 }}>Have feedback, comments or have an<br/>event? We want to hear from you!</Typography>
            
            <Typography style={{color: "black", textAlign:"center", paddingTop:10, fontWeight:"600", fontSize:16 }}>support@valenow.com</Typography>
        </Box>

        <Footer />

      </Page>
    );
  }


export default AboutUsPage;