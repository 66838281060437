import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ValeLaunchButton, { HEIGHT } from '../../../../molecules/ValeLaunchButton';
const WIDTH = 136


export type Props = {
    notAvailable?: boolean
    onOrder?: () => Promise<boolean>
    onStoreClosed?: () => void
}

export const CheckThat: React.FC  = () => {
    return (
            <div style={{
                width: WIDTH,
                justifyContent: "center",
                display: "flex",
                alignItems: 'center',
                height: HEIGHT,
                borderWidth: 2,
                borderColor: "#FF00B8",
                borderStyle: "solid",
                borderRadius: 10,
                color: '#FF00B8',
                fontSize: 15,
                fontFamily: 'Inter',
                fontWeight: '700',
                backgroundColor: 'white',
            }}>
                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.16999 18L0 9.75951L2.35753 7.05072L7.16999 12.5818L18.1175 0L20.475 2.70879L7.16999 18Z" fill="#FF00B8"/>
                </svg>
                ADDED!
            </div>
    )
}

const OrderButton: React.FC<Props>= (props) => {
    const [isAnimating, setIsAnimating] = React.useState(false);

    const handleAnimationComplete = () => {
        setTimeout(() => {
          setIsAnimating(false);
        }, 500); // Duration for which the image will be shown before reverting back to the button
      };


    if ( props.onStoreClosed ) {
        return <div style={{width: 140, height: 32, textAlign: 'center'}}>
            <span style={{color: '#4712B1', fontSize: 12, fontFamily: 'Inter', fontStyle: 'italic', fontWeight: 500}}>🕖 Currently closed</span>
            <br/>
            <span style={{color: '#E30A8F', fontSize: 12, fontFamily: 'Inter', fontWeight: 700, textDecoration: 'underline'}} onClick={props.onStoreClosed}>SEE OPEN HOURS</span>
            </div>
    }
    
    const text = props.notAvailable ? "ARG! SOLD OUT" : "ORDER"
    const body = isAnimating ? <motion.div onAnimationComplete={handleAnimationComplete} key="image"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }} ><CheckThat/></motion.div> : (
        <motion.div key="button" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ValeLaunchButton width={WIDTH} disabled={props.notAvailable} onPress={async ()=>{
                const result = await props.onOrder?.()
                if (result ) {
                    setIsAnimating(true)
                }
            }}>
                {text}
            </ValeLaunchButton>
        </motion.div>
    )

    return <AnimatePresence><div style={{height: HEIGHT, width: WIDTH}}>{body}</div></AnimatePresence>
}

export default OrderButton