import { FC, useMemo } from "react"
import { Components } from "../../../../vale_common"
import ValeOrderReceipt from "../../../molecules/ValeOrderReceipt"

type OrderHistoryProps = {
    orders?: Components.Schemas.Order[]
}
const OrderHistory: FC<OrderHistoryProps> = (props) => {
    const orders = useMemo(()=> {
        if (!props.orders || props.orders?.length === 0) return null;
        const sortedOrders = [...props.orders]
        sortedOrders.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime())
        return sortedOrders.map((order) => {
            return <ValeOrderReceipt key={order.id} order={order}/>
        }   )   
    }, [props.orders])
    return (
        <div style={mainConatiner}>
            <div style={headerContainer}>
                ORDER HISTORY
            </div>
            <div style={orderContainer}>
            {orders}
            </div>
        </div>
    )
}

export default OrderHistory


const mainConatiner: React.CSSProperties = {
    backgroundColor: "#B780F7",
    border: '2px solid',
    borderColor: "#2C2C2C",
    borderRadius: 10,
    margin: "auto",
    overflow: "hidden",
    marginBottom: 20,
    width:"90%"
};

const orderContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    rowGap: "26px",
    width: "100%",
    paddingTop: "26px",
    paddingBottom: "26px"
}

const headerContainer: React.CSSProperties = {
    width: "100%",
    height: 46,
    backgroundColor: "#9747FF",
    borderBottom: '2px solid black',
    borderColor: "#2C2C2C",
    color: '#2C2C2C',
    fontSize: 26,
    fontFamily: 'BN Dime Display',
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

}
  