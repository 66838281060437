import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, User, getAuth } from "firebase/auth";
import { Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { createContext, useContext } from "react";
import { getEnv } from "./env";
import ValeMessaging from "./ValeMessaging";

import axios from "axios";
import OpenAPIClientAxios from "openapi-client-axios";
import { Client as ValeOpenApiClient } from "../vale_common";
import { payments } from '@square/web-sdk';
import { Payments } from "@square/web-payments-sdk-types";


const a = axios.defaults;
const env = getEnv();

export default class ValeSystem {
  firebaseApp!: FirebaseApp;
  firebaseAuth!: Auth;
  firebaseMessaging!: Messaging;
  valeMessaging!: ValeMessaging;
  squarePaymentsClient!: Payments | null;
  valeClientOpenapi!: ValeOpenApiClient;

  async init() {
    
    try {
      this.squarePaymentsClient = await payments(
        env.squareAppId,
        env.squareDefaultLocationId,
      );
    } catch (e) {
      console.error("Failed to initialize Square Payments Client : ", e)
    }
    
    this.firebaseApp = initializeApp(env.firebaseConfig);
    this.firebaseAuth = getAuth(this.firebaseApp);
    
    this.valeMessaging = new ValeMessaging();  

    const definition = env.apiUrl + "openapi.json";
    const url = env.apiUrl.toString();
    const openApiClient = new OpenAPIClientAxios({
      definition,
      withServer: { url },
    });

    this.valeClientOpenapi = await openApiClient.init<ValeOpenApiClient>();

    this.valeClientOpenapi.interceptors.request.use(async (config) => {
      const token = await this.firebaseAuth.currentUser?.getIdToken();
      config!.headers!["Authorization"] = "bearer " + token;
      return config;
    });
  }
}

export const ValeContext = createContext<ValeSystem | null>(null);
export const useValeContext = () => useContext(ValeContext);
