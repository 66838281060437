import React, { useMemo } from "react";
import Page from "../../../templates/Page";
import MyAccountImage from "./MyAccountImage";
import { Box, Typography } from "@mui/material";
import { useSessionContext } from "../../../../system/SessionProvider";
import ValeLaunchButton from "../../../molecules/ValeLaunchButton";
import Footer from "../../../molecules/footer";
import { useValeContext } from "../../../../system/ValeSystem";
import { useNavigation } from "@react-navigation/native";
import OrderHistory from "./OrderHistory";
import { splitNames } from "../../../utils/userUtils";
import { PageTitle } from "../../../atoms/TextStyles";

const AccountPage = () => {

  const session = useSessionContext();
  const user = session?.userData?.userRecord;

  const vale = useValeContext();
  const navigation = useNavigation();
    
  console.log(JSON.stringify( user ));

  const profileTitle: React.CSSProperties = {
    fontFamily: "BN Dime Display",
    fontSize: 20,
    fontWeight: 400,
    color: "#2C2C2C",
    marginBottom: 1,
  };

  const profileData: React.CSSProperties = {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 400,
    color: "#2C2C2C",
    marginBottom: 30,
    marginTop: 6,
  };


  const orders = useMemo(() => {
    const values = session?.orderMap?.values()
    if ( !values ) return
    return [...values]
  }
  ,[session?.orderMap])

  const names = splitNames(user?.displayName)

  return (
    <Page requireAuth={'login'}>
      <MyAccountImage />

      <PageTitle style={{marginBottom:"40px"}}>YOUR ACCOUNT</PageTitle>
      <Box width="90%"
                style={{
                  backgroundColor: "#F87BD3",
                  borderTop: '2px solid',
                  borderLeft: '2px solid',
                  borderRight: '2px solid',
                  borderBottom: '2px solid',
                  borderRadius: 10,
                  margin: "auto",
                  overflow: "hidden",
                  marginBottom: 20
                }}>

                <div
                  style={{
                    width: "100%",
                    height: 42,
                    backgroundColor: "#FF00B8",
                    borderBottom: '2px solid black'
                  }}>
                    <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center", height : "100%"}}>
                    <div style={{fontFamily: "BN Dime Display", color: "#2C2C2C",  fontWeight: 400, fontSize: 26, width: 250}}>
                      MY PROFILE</div>
                      <div style={{
                        color: '#2C2C2C',
                        fontSize: 14,
                        fontFamily: 'Inter',
                        fontWeight: 700 ,
                        textDecoration: 'underline'}}
                      onClick={async ()=> {
                              await vale?.firebaseAuth.signOut();
                              navigation.reset({
                                index: 0,
                                routes: [{ name: "landing" }],
                              });
                  }}>logout</div>
                      </div>
                  </div>


                  <div style={{marginLeft:20, marginBottom: 30}}>
                    
                    <p style={profileTitle}>FIRST NAME:</p>
                    <p style={profileData}>{names?.firstName}</p>

                    <p style={profileTitle}>LAST NAME:</p>
                    <p style={profileData}>{names?.lastName}</p>

                    <p style={profileTitle}>PHONE NUMBER:</p>
                    <p style={profileData}>{user?.phoneNumber}</p>

                  </div>
                  
                  <Box height={200} style={{borderTop: '2px solid', backgroundColor: "#FF00B8"}}>

                      <h3 style={{margin: "auto", width: "60%", fontFamily: "BN Dime Display", fontWeight: 200, color: "#2C2C2C", fontSize: 20, marginTop: 30}}>NEED TO MAKE CHANGES?</h3>
                      <p style={{width: "55%", margin: "auto", marginTop: 5, color: "white", textAlign: "center", fontSize: 16, fontFamily: "Inter"}}>Let us know and we'll get back to you ASAP 🤞</p>
                    <br/>
                    <center>
                      <ValeLaunchButton
                        onPress={() => {
                          navigation.navigate("contact");
                        }}
                        width={165}
                        height={50}
                        
                        fontStyle={{fontSize:18}
                      }
                      >
                        EMAIL US
                      </ValeLaunchButton>
                    </center>

                  </Box>

                  
              
          </Box>
          <OrderHistory orders={orders}/>


          <Footer />
    </Page>
  );
};



export default AccountPage;
