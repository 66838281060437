import { useEffect, useMemo, useState } from "react";

const TIME_OUT = 10000;
export const useTimeToRender = (track: any, description: string) => {
  const initTimer = useMemo(() => performance.now(), []);
  const [renderTimeout, setRenderTimeout] = useState<NodeJS.Timeout | null>(
    setTimeout(() => {
      console.warn(`Time to Render exceeded for : ${description}`);
    }, TIME_OUT),
  );

  useEffect(() => {
    return () => {
      if (renderTimeout) {
        //This is used to clear the timeout when the component is unmounted
        clearTimeout(renderTimeout);
      }
    };
  }, [renderTimeout]);

  return useEffect(() => {
    if (track) {
      if (renderTimeout) {
        clearTimeout(renderTimeout);
        setRenderTimeout(null);
        const t1 = performance.now();
        console.log(`Time to render : ${t1 - initTimer} ms : ${description}`);
      }
    }
  }, [track, renderTimeout]);
};
