import {
  MD3DarkTheme,
  MD3LightTheme,
  MD3Theme,
  configureFonts,
} from "react-native-paper";
import {
  VALE_ACCENT_COLOR as VALE_SECONDARY_COLOR,
  VALE_PRIMARY_COLOR,
} from "./colors";
import { Platform } from "react-native";
import { createTheme } from "@mui/material";

const defaultFont = {
  fontFamily: "Inter",
  fontWeight: "500",
  letterSpacing: 0.5,
  lineHeight: 22,
  fontSize: 12,
};

const fontConfig = {
  default: defaultFont,
};

const valePaperTheme: MD3Theme = {
  ...MD3LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    primary: VALE_PRIMARY_COLOR,
    secondary: VALE_SECONDARY_COLOR,
    outline: VALE_PRIMARY_COLOR,
    onSurface: VALE_PRIMARY_COLOR,
    onSurfaceVariant: VALE_SECONDARY_COLOR,
    onPrimary: "white",
  },
  //@ts-ignore
  fonts: configureFonts({ config: fontConfig }),
};


export const valeMuiTheme = createTheme({

  typography: {
    fontFamily: 'Inter, BN Dime Display',
    h1: {
      fontFamily: "BN Dime Display",
      fontWeight: '400',
      fontSize: 42,
      color: "#2C2C2C"
    },
    h3: {
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 600,
      // "WebkitTextStroke": "1px black",
      
      // textStroke: '1px black',
      color: "#2C2C2C",
      lineHeight: '23px',
      // textShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)",
    }
  },
});
export default valePaperTheme;
