import React, { useState } from 'react';

const SmsCheckBox = () => {

  const [imageSrc, setImageSrc] = useState("/check_yes.png");

  const handleImageClick = () => {
    setImageSrc((prevSrc) =>
      prevSrc === "/check_yes.png" ? "/check_no.png" : "/check_yes.png"
    );
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px", width: "70%", display: 'flex', alignItems: 'center' }}>
      <img
        id="sms_checkbox"
        src={imageSrc}
        onClick={handleImageClick}
        style={{ marginRight: '8px', cursor: 'pointer' }}
        alt="checkbox"
      />
      <span style={{ fontFamily: "Inter", color: "white", fontSize: "14px" }}>
        Opt-in to receive <span>SMS messages</span> from Vale
      </span>
    </div>
  );
};

export default SmsCheckBox;
