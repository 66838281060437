export const VisaLogo =  <svg width="84" height="28" viewBox="0 0 84 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.8384 1.88854C58.9124 1.13656 56.8766 0.759214 54.8268 0.774267C48.1978 0.774267 43.5161 4.35237 43.483 9.48867C43.4416 13.2621 46.8099 15.3885 49.3579 16.6493C51.9722 17.9456 52.8464 18.7624 52.8381 19.9211C52.8215 21.6835 50.75 22.4826 48.8234 22.4826C46.176 22.4826 44.73 22.0875 42.5052 21.0975L41.6807 20.6847L40.7403 26.5979C42.3519 27.317 45.2562 27.9341 48.2558 27.9874C55.3032 27.9874 59.9103 24.4315 59.9642 18.9666C60.0222 15.9523 58.1992 13.675 54.3627 11.7927C52.0302 10.5719 50.5842 9.75503 50.5842 8.51646C50.5842 7.41994 51.823 6.24796 54.4124 6.24796C56.1358 6.20328 57.8485 6.54974 59.438 7.26457L60.0595 7.562L60.9999 1.84858L60.8384 1.88854ZM78.0405 1.25371H72.8616C71.2499 1.25371 70.036 1.72872 69.3317 3.4423L59.3717 27.6189H66.4191L67.8278 23.6546L76.4288 23.6635C76.6318 24.5913 77.2533 27.6189 77.2533 27.6189H83.4679L78.0405 1.25371ZM33.9497 1.03175H40.6657L36.4646 27.4103H29.7569L33.9497 1.02287V1.03175ZM16.9009 15.5706L17.597 19.233L24.1596 1.25371H31.2733L20.7043 27.5834H13.6072L7.80686 5.28906C7.71292 4.92145 7.4923 4.60573 7.18954 4.40564C5.09895 3.24847 2.88382 2.37033 0.59375 1.79087L0.684898 1.23595H11.4942C12.9609 1.2981 14.1416 1.79087 14.5352 3.46894L16.8968 15.5839L16.9009 15.5706ZM69.7543 18.2608L72.4307 10.8826C72.3976 10.9625 72.9818 9.35993 73.3215 8.36996L73.7814 10.6473L75.335 18.2564H69.7502L69.7543 18.2608Z" fill="#2C2C2C"/>
</svg>

export const MasterCardLogo = <svg width="96" height="18" viewBox="0 0 96 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2264_979)">
<path d="M84.5368 2.32992e-08C84.3695 -4.53918e-05 84.2092 0.0663035 84.0908 0.184465C83.9725 0.302627 83.9059 0.462932 83.9058 0.630151V5.046H95.5726L90.2596 0.00136769L84.5368 2.32992e-08ZM96 6.38186C96.0001 6.29911 95.9838 6.21716 95.9521 6.14071C95.9204 6.06426 95.874 5.99482 95.8154 5.93637C95.7568 5.87792 95.6873 5.83161 95.6108 5.8001C95.5343 5.76858 95.4523 5.75249 95.3695 5.75274H90.954V17.4193L95.998 12.1049L96 6.38186ZM89.6161 17.8427C89.783 17.8423 89.9429 17.7756 90.0608 17.6574C90.1787 17.5393 90.2449 17.3791 90.2449 17.2122V12.7969H78.5785L83.8907 17.8415L89.6161 17.8427ZM78.1523 11.4615C78.1523 11.5443 78.1686 11.6263 78.2003 11.7028C78.2319 11.7792 78.2783 11.8487 78.3368 11.9073C78.3954 11.9658 78.4648 12.0123 78.5413 12.044C78.6178 12.0757 78.6997 12.092 78.7825 12.092H83.1983V0.42466L78.153 5.73769L78.1523 11.4615Z" fill="#2C2C2C"/>
<path d="M24.6827 2.13003V7.5176H17.176V2.13003L14.4429 2.12901V15.7135H17.176V9.87751H24.6827V15.7135H27.4208V2.13003H24.6827ZM61.053 2.13003V15.7111L73.0481 15.7104L71.5281 13.3204H63.7957V9.87751H71.2832V7.57436H63.7957V4.46498H71.5155L73.0047 2.13003H61.053ZM4.2813 2.12832C1.43673 2.12832 0 3.85602 0 6.37201V11.4435C0 14.3542 1.85045 15.7138 4.2666 15.7138L12.8425 15.7121L11.2538 13.2297H4.79588C3.42463 13.2297 2.8273 12.734 2.8273 11.1985V6.59802C2.8273 5.11342 3.32975 4.53421 4.83247 4.53421H11.3212L12.8475 2.12849L4.2813 2.12832ZM49.0559 2.12439C47.4125 2.12439 45.7306 3.11099 45.7306 5.6504V6.30278C45.7306 8.94835 47.3506 9.9594 48.9701 9.96675H54.6587C55.2494 9.96675 55.7296 10.0645 55.7296 11.0636L55.7289 12.2183C55.7142 13.1134 55.2654 13.3204 54.6383 13.3204H47.1553L45.6224 15.7103H54.8134C57.0311 15.7103 58.5357 14.6081 58.5357 12.0598V11.1236C58.5357 8.66046 57.1379 7.49811 54.9697 7.49811H49.5383C48.9357 7.49811 48.5167 7.3328 48.5167 6.44091L48.516 5.50201C48.516 4.74244 48.8046 4.46498 49.506 4.46498L56.6321 4.46224L58.122 2.12183L49.0559 2.12439ZM35.3649 2.13208L28.9234 15.7121H31.9683L33.2228 12.9076H40.222L41.4725 15.7121H44.5296L38.0734 2.13191L35.3649 2.13208ZM36.7148 4.95084L39.1901 10.596H34.255L36.7148 4.95084Z" fill="#2C2C2C"/>
</g>
<defs>
<clipPath id="clip0_2264_979">
<rect width="96.0003" height="18" fill="white"/>
</clipPath>
</defs>
</svg>


export const DiscoverLogo = <svg width="86" height="55" viewBox="0 0 86 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2266_1396)">
<path d="M1.10352 2.97539V52.0341C1.10352 53.0667 1.95 53.9116 2.98459 53.9116H55.1656C69.7721 53.9116 81.6229 42.0928 81.6229 27.5047C81.6229 12.9261 69.7815 1.0979 55.1656 1.0979H2.98459C1.94059 1.0979 1.10352 1.93338 1.10352 2.97539Z" fill="white"/>
<path d="M7.46195 21.5625H4.12305V33.1841H7.44314C9.20195 33.1841 10.4811 32.7711 11.6003 31.8417C12.9265 30.7434 13.7165 29.0912 13.7165 27.3827C13.7071 23.9563 11.1395 21.5625 7.46195 21.5625ZM10.1143 30.2928C9.39946 30.9312 8.47773 31.2128 7.0105 31.2128H6.39915V23.5339H7.0105C8.47773 23.5339 9.37124 23.7967 10.1143 24.4726C10.9043 25.1673 11.3746 26.2562 11.3746 27.3639C11.3746 28.481 10.8949 29.5981 10.1143 30.2928Z" fill="#231F20"/>
<path d="M17.0265 21.5625H14.7598V33.1841H17.0265V21.5625Z" fill="#231F20"/>
<path d="M22.5757 26.0215C21.212 25.5146 20.8169 25.1861 20.8169 24.5571C20.8169 23.8249 21.5317 23.271 22.5099 23.271C23.1871 23.271 23.7514 23.5526 24.3439 24.2098L25.529 22.6608C24.5509 21.8066 23.3846 21.3748 22.1055 21.3748C20.0457 21.3748 18.475 22.8016 18.475 24.7073C18.475 26.3125 19.2086 27.1293 21.3436 27.8896C22.2277 28.1994 22.6886 28.4153 22.9143 28.5468C23.3658 28.8378 23.5915 29.2602 23.5915 29.7483C23.5915 30.6871 22.8391 31.3911 21.8233 31.3911C20.7417 31.3911 19.867 30.8467 19.3403 29.8422L17.873 31.2503C18.917 32.7805 20.1774 33.4658 21.908 33.4658C24.2687 33.4658 25.924 31.8981 25.924 29.6545C25.924 27.8051 25.1622 26.9697 22.5757 26.0215Z" fill="#231F20"/>
<path d="M26.6484 27.3828C26.6484 30.7998 29.3384 33.4471 32.7901 33.4471C33.7683 33.4471 34.6054 33.2593 35.64 32.7712V30.1051C34.7277 31.0157 33.9282 31.3818 32.8936 31.3818C30.6081 31.3818 28.981 29.7296 28.981 27.3734C28.981 25.1392 30.6551 23.3837 32.7901 23.3837C33.8718 23.3837 34.69 23.7686 35.64 24.6886V22.0226C34.643 21.5156 33.8247 21.3091 32.8466 21.3091C29.4042 21.2997 26.6484 24.0033 26.6484 27.3828Z" fill="#231F20"/>
<path d="M53.6415 29.3728L50.5377 21.5625H48.0547L53.0019 33.4845H54.2246L59.2471 21.5625H56.7923L53.6415 29.3728Z" fill="#231F20"/>
<path d="M60.2832 33.1841H66.7165V31.2222H62.5499V28.0868H66.566V26.1154H62.5499V23.5339H66.7165V21.5625H60.2832V33.1841Z" fill="#231F20"/>
<path d="M75.7171 24.9984C75.7171 22.8205 74.2123 21.572 71.5976 21.572H68.2305V33.1937H70.4972V28.5281H70.7981L73.9395 33.1937H76.7329L73.0648 28.2934C74.7672 27.9461 75.7171 26.7727 75.7171 24.9984ZM71.1555 26.9135H70.4878V23.3932H71.1838C72.5946 23.3932 73.3658 23.9846 73.3658 25.1205C73.3752 26.2845 72.604 26.9135 71.1555 26.9135Z" fill="#231F20"/>
<path d="M77.5234 22.1727C77.5234 21.9662 77.3823 21.8535 77.1378 21.8535H76.8086V22.8767H77.0531V22.4825L77.3447 22.8767H77.6457L77.3071 22.4543C77.4388 22.4261 77.5234 22.3229 77.5234 22.1727ZM77.0908 22.3135H77.0531V22.0413H77.1002C77.2224 22.0413 77.2883 22.0882 77.2883 22.1727C77.2789 22.2666 77.213 22.3135 77.0908 22.3135Z" fill="#231F20"/>
<path d="M77.1845 21.478C76.686 21.478 76.291 21.8723 76.291 22.3698C76.291 22.8674 76.6955 23.2616 77.1845 23.2616C77.6736 23.2616 78.0686 22.858 78.0686 22.3698C78.0686 21.8817 77.6642 21.478 77.1845 21.478ZM77.1751 23.1021C76.7801 23.1021 76.4603 22.7735 76.4603 22.3698C76.4603 21.9662 76.7801 21.6376 77.1751 21.6376C77.5701 21.6376 77.8805 21.9756 77.8805 22.3698C77.8899 22.7735 77.5701 23.1021 77.1751 23.1021Z" fill="#231F20"/>
<path d="M48.7051 27.364C48.7051 30.7622 45.9493 33.5221 42.5352 33.5221C39.121 33.5221 36.3652 30.7716 36.3652 27.364C36.3652 23.9657 39.121 21.2058 42.5352 21.2058C45.9493 21.2058 48.7051 23.9657 48.7051 27.364Z" fill="#231F20"/>
<g opacity="0.5">
<path d="M48.7051 27.364C48.7051 30.7622 45.9399 33.5221 42.5352 33.5221C39.1304 33.5221 36.3652 30.7716 36.3652 27.364C36.3652 23.9657 39.121 21.2058 42.5352 21.2058C45.9493 21.2152 48.7051 23.9657 48.7051 27.364Z" fill="#231F20"/>
</g>
<path d="M83.016 0.15918H2.98567C1.42438 0.15918 0.164062 1.4171 0.164062 2.97541V52.0341C0.164062 53.5924 1.42438 54.8504 2.98567 54.8504H83.016C84.5773 54.8504 85.8376 53.5924 85.8376 52.0341V2.97541C85.8376 1.4171 84.5773 0.15918 83.016 0.15918ZM81.624 27.5048C81.624 42.0834 69.7826 53.9116 55.1667 53.9116H2.98567C1.95108 53.9116 1.1046 53.0667 1.1046 52.0341V2.97541C1.1046 1.94279 1.95108 1.09792 2.98567 1.09792H55.1667C69.7732 1.09792 81.624 12.9167 81.624 27.5048Z" fill="#231F20"/>
</g>
<defs>
<clipPath id="clip0_2266_1396">
<rect width="86" height="55" fill="white"/>
</clipPath>
</defs>
</svg>
