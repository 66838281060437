import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import Page from "../../templates/Page";
import { useValeContext } from "../../../system/ValeSystem";
import {
  signInWithCustomToken
} from "firebase/auth";
import PhoneNumberInput, { E164Number } from "./PhoneNumberInput";
import ConfirmCodeInput from "./ConfirmCodeInput";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import { handleUIException } from "../../utils/exception";
import { AuthPageType } from "../pages_param_list";
import { Box, Container, Typography } from "@mui/material";
import {PageTitle, PageSubtitle} from "../../atoms/TextStyles";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { parsePhoneNumber } from 'awesome-phonenumber'
import SmsCheckBox from "./SmsCheckBox";

const PHONE_NUMBER_ID = "ValePhoneNumberInputCaptcha";
const PhoneNumberEntryPage: React.FC<{
  onComplete: () => Promise<void>;
  busy?: boolean;
  authType?: AuthPageType | boolean;
}> = (props) => {
  
  const sendCodeRef = useRef(null);
  const [apiBusy, setApiBusy] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>();
  const [confirmationCode, setConfirmationCode] = useState("");

  const [challengeId, setChallengeId] = useState<string | undefined>();

  const [imageSrc, setImageSrc] = useState("/check_yes.png");

  const system = useValeContext();
  // useEffect(() => {
  //   // 'recaptcha-container' is the ID of an element in the DOM.

  //   //@ts-ignore
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     PHONE_NUMBER_ID,
  //     {
  //       size: "invisible",
  //     },
  //     system?.firebaseAuth!,
  //   );
  // }, []);

  const onSendCode = useCallback(async () => {
    try {
      if ( !phoneNumber )  return;
      setApiBusy(true);
      const phoneParsed = parsePhoneNumber(phoneNumber);
      const e16Phone = phoneParsed.number?.e164

      const createAuthChallengeResp = await system?.valeClientOpenapi.createAuthChallenge(null, {
        phoneNumber
      });
      
      if ( !createAuthChallengeResp?.data?.challengeId ) {
        throw new Error("Failed to create challenge")
      }
      setChallengeId(createAuthChallengeResp?.data?.challengeId)
      
      //const formatedPhoneNumber = phone(phoneNumber);

      // await system?.firebaseAuth!.setPersistence(browserLocalPersistence);
      // const provider = new PhoneAuthProvider(system?.firebaseAuth!);

      //@ts-ignore
      // const recaptchaVerifier = window.recaptchaVerifier;
      // console.log("verifyPhoneNumber : ", phoneNumber);
      // const verificationId = await provider.verifyPhoneNumber(
      //   { phoneNumber: phoneNumber! },
      //   recaptchaVerifier,
      // );

      
      
    } catch (e) {
      handleUIException(e);
    } finally {
      setApiBusy(false);
    }
  }, [phoneNumber]);


  const actionComponent = useMemo(() => {
    if (challengeId) {
      return <div style={{width: 277, height: 17,  marginTop: "20px", marginBottom: "20px"}}>
        <span style={{color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: 500}}>Didn’t receive a code? </span>
        <span onClick = {onSendCode}
        style={{color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, textDecoration: 'underline'}}>SEND NEW ONE</span>
        </div>
    }

    return (
      <SmsCheckBox />
    );
  }, [onSendCode, challengeId]);

  const back = useMemo(() => {
    if ( !challengeId ) return null
    return <div style={{position: "absolute", left: 16}} onClick={()=> {setChallengeId(undefined)}}><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.377 10L9 20L18.377 30" stroke="#2C2C2C" strokeWidth="2"/>
    <path d="M31 21C31.5523 21 32 20.5523 32 20C32 19.4477 31.5523 19 31 19V21ZM9 21H31V19H9V21Z" fill="#2C2C2C"/>
    </svg>
    </div>
  }, [challengeId]);

  const onVerifyCode = useCallback(async () => {
    try {
      
      hideButtonShowSpinner();
      setApiBusy(true);
      
      const confirmAuthChallengeResp = await system?.valeClientOpenapi.confirmAuthChallenge(null, {
        challengeId,
        challenge: confirmationCode
      })

      if ( !confirmAuthChallengeResp?.data?.token) {
        throw new Error("Failed to confirm challenge")
      }
      
      const userCreds = await signInWithCustomToken(system?.firebaseAuth!, confirmAuthChallengeResp?.data?.token);
      if ( !userCreds.user) {
        throw new Error("Failed to sign in")
      }

      await props.onComplete();
    } catch (e) {
      handleUIException(e);
    } finally {
      showButtonHideSpinner();
      setApiBusy(false);
    }
  }, [confirmationCode, challengeId]);

  function hideButtonShowSpinner() {
    var btn = document.getElementById("btn");
    if (btn) btn.style.opacity = "0";

    var spinner = document.getElementById("spinner");
    if (spinner) spinner.style.opacity = "100";
  }

  function showButtonHideSpinner() {
    var btn = document.getElementById("btn");
    if (btn) btn.style.opacity = "100";

    var spinner = document.getElementById("spinner");
    if (spinner) spinner.style.opacity = "0";
  }
  
  const title = !challengeId ? props.authType === "login" ? 'WELCOME BACK TO VALE!' : 'WHAT’S YOUR NUMBER?' : "ENTER THE SECRET CODE"

  const subtitle = !challengeId
    ?  props.authType === "login" ? "Sign-in using your phone number" : "Creating an account is super easier, we just need your phone number." 
    : <><span>Enter the 6-digit verfication code sent to :</span><br/><span>{formatPhoneNumberIntl(phoneNumber!)}.</span></>

  const body = !challengeId ? (
    <>
      <PhoneNumberInput
        autoFocus={true}
        placeHolder="Enter your phone number"
        onChange={(phoneNumber) => {
          setPhoneNumber(phoneNumber);
        }}
      />

      <center>
      {actionComponent}
      </center>


      <View style={{ width: "100%", alignItems: "center", paddingTop: 16 }}>
        <ValeLaunchButton fontStyle={{fontSize: 18, fontWeight: 700}} disabled={apiBusy || props.busy} onPress={onSendCode}>
          SUBMIT
        </ValeLaunchButton>
      </View>
    </>
  ) : (
    <View>
      <ConfirmCodeInput onChange={setConfirmationCode} autoFocus={true} />
      
      <center>
      {actionComponent}
      </center>

      <div id="btn">
        <View style={{ width: "100%", alignItems: "center", paddingTop: 16 }}>
          <ValeLaunchButton
            fontStyle={{fontSize: 18, fontWeight: 700}}
            disabled={apiBusy || props.busy}
            onPress={onVerifyCode}
          >
            CONFIRM
          </ValeLaunchButton>
        </View>
      </div>

      <div id="spinner" style={{ opacity: "0" }}>
        <View style={{ width: "100%", alignItems: "center" }}>
          <img src="/temploading.gif" style={{ width: "80%" }} />
        </View>
      </div>
    </View>
  );


  
  return (
    <Page>
      {back}
      <PageTitle>{title}</PageTitle>
      <PageSubtitle>{subtitle}</PageSubtitle>
      <Container
        style={{
          marginTop: 20,
        }}
      >
        {body}
        <div id="ValePhoneNumberInputCaptcha"></div>
      </Container>
    </Page>
  );
};

export default PhoneNumberEntryPage;
