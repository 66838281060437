import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Page from "../../templates/Page";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { CheckoutStackParamList } from "./checkout_param_list";

//import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";

import { useSubmitOrder } from "./checkout_process";
import { useSessionContext } from "../../../system/SessionProvider";

import { PageSubtitle, PageTitle } from "../../atoms/TextStyles";
import { Typography } from "@mui/material";
import { getEnv } from "../../../system/env";
import { PaymentsInputRefProps } from "./PaymentsInput";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import { ApplePayButton, GooglePayButton } from "./DigitalWalletPayment";
import CardPaymemntSelection from "./CardPaymentSelection";
import { useValeStackNavigation } from "../../../nav/nav_utils";
import Lottie from "react-lottie";
import * as animationData from "./animations/placing_order.json"
import { FOOT_PADDING } from "../../molecules/footer";
import PaymentCredits from "./PaymentCredits";
import { useCartContext } from "../../../system/CartProvider";

const env = getEnv();

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const PaymentSelectionPage: React.FC<
  NativeStackScreenProps<CheckoutStackParamList, "paymentSelection">
> = (props) => {
  const session = useSessionContext();
  const navigation = useValeStackNavigation();
  const paymentInputRef = useRef<PaymentsInputRefProps>(null);
  const cart = useCartContext()
  
  const submitOrder = useSubmitOrder()
  const [placingOrder, setPlacingOrder] = useState(false);
  const [digitalBusy, setDigitalBusy] = useState(false);
  const onNewPaymentCheckout = useCallback(async (paymentId: string | undefined, programId?: string) => {
    try {
      if ( !paymentId && !programId && ( cart?.totalCents && cart?.totalCents > 0 )) {
        console.warn("onNewPaymentCheckout paymentId is undefined or programId is undefined")
        setPlacingOrder(false)
        return
      }
      setPlacingOrder(true);
      const newOrder = await submitOrder(paymentId, programId);
      navigation.push("home")
      
    } catch ( e ) {
      console.error("Error submitting order: ", e)
    }
    setPlacingOrder(false)
  }, [paymentInputRef, submitOrder, cart?.totalCents])
  
  const body = placingOrder ?  <Lottie options={defaultOptions}
  height={400}
  width={400}/> : <>
  <div style={{height:"100%", paddingBottom: FOOT_PADDING}}>
  <PageTitle style={{marginTop: 20}}>FASTEST CHECKOUT IN THE WEST</PageTitle>

  { cart?.totalCents === 0 ? <PageSubtitle style={{marginTop: 20}}>Your order is free!</PageSubtitle> : <>
  <PageSubtitle style={{marginTop: 20}}>Choose your payment method</PageSubtitle>
  <PaymentCredits placingOrder={placingOrder} onPaymentProcess={onNewPaymentCheckout}/>
  <div style={{display: "flex", justifyContent: "center",  margin:"auto", marginTop: 10, maxWidth: "329px", paddingTop:20, color: "white"}}>
    <ApplePayButton busy={placingOrder || digitalBusy } onBusy={setDigitalBusy} onPaymentProcess={onNewPaymentCheckout}/>
    <div style={{width: 8}}/>
    <GooglePayButton busy={placingOrder || digitalBusy } onBusy={setDigitalBusy} onPaymentProcess={onNewPaymentCheckout}/>
  </div>

  <Typography style={{width:"80%", margin: "auto", textAlign: "center", paddingTop:20, color: "white"}}>or</Typography>
  <CardPaymemntSelection busy={placingOrder || digitalBusy} onCardAdded={(paymentId)=> onNewPaymentCheckout(paymentId)} ref={paymentInputRef}/>
  </> }
</div>
  <div style={{width: "100%", position: "fixed", height: FOOT_PADDING, bottom: 0, alignItems: "center", backgroundColor: "#7C50FF", display: "flex", justifyContent: "space-around"}}>
    <ValeLaunchButton disabled={placingOrder || digitalBusy} fontStyle={{fontSize:18, fontWeight: 700}} onPress={() => {
      if( navigation.canGoBack() ) {
        navigation.goBack()
      } else {
        navigation.push("order", {screen :"review", params: {}})
      }
      }} secondary width={124}>BACK</ValeLaunchButton>
    <ValeLaunchButton disabled={placingOrder || digitalBusy} fontStyle={{fontSize:18, fontWeight: 700}} withArrow={true} onPress={async () => {
      setDigitalBusy(true)
      try {
        const cardId = await paymentInputRef.current?.processInput();
        onNewPaymentCheckout(cardId)
      } catch ( e ) {
        console.error("Error processing payment: ", e)
      }
      setDigitalBusy(false)
    }} width={165}>CHECKOUT</ValeLaunchButton>
</div>
</>
  
  return (
    <Page>
      {body}
    </Page>
  );
};

export default PaymentSelectionPage;