import { useValeStackNavigation } from "../../../nav/nav_utils";
import { SeralizeOrderSelectionState } from "../order/menu_nav_utils";
import { useValeContext } from "../../../system/ValeSystem";
import { OrderStateList } from "../../../vale_common";

export const useGoToCheckoutFlow = () => {
  const navigaiton = useValeStackNavigation();
  const valeContext = useValeContext();

  return (order: OrderStateList, orderName?: string, orderId?: string) => {
    const user = valeContext?.firebaseAuth.currentUser;
    //User needs to create an account and have a phone numberclea
    if (!user?.phoneNumber) {
      navigaiton.navigate("checkout", {
        screen: "phoneNumberEntry",
        params: { order: SeralizeOrderSelectionState(order) },
      });
      return;
    }

    if (orderId) {
      navigaiton.navigate("home");
      return;
    }

    //User needs to add a payment method

    navigaiton.navigate("checkout", {
      screen: "paymentSelection",
      params: { order: SeralizeOrderSelectionState(order), orderName },
    });
    return;
  };
};
