import { Firestore } from "firebase/firestore";
import PubNub from "pubnub";
import events from "events";
import { VALE_USER_EVENT, ValeUserEventData } from "../vale_common";
import { getMessaging } from "firebase/messaging";
import * as Ably from "ably"

const ABLYKEY = "Y1itSw.hTYDlA:PRdD6QW9yu4AUpodWSXl4-UKZdNDQ6vkKJnsBePcaPI"
class ValeMessaging {
  private _token: string | undefined;
  private _userId?: string;
  //private _pubnub?: PubNub;
  private _userChannel?: Ably.RealtimeChannel
  private _em: events.EventEmitter;
  private _ably: Ably.Realtime
  constructor() {
    this._em = new events.EventEmitter();
    this._ably = new Ably.Realtime(ABLYKEY)   

    this._ably.connection.once("connected", () => {
      console.log("Connected to Ably!")
    })
  }

  addValeUserDataEventListener(listener: (event: ValeUserEventData) => void): () => void {
    const _listener = (_event: ValeUserEventData) => {
      listener(_event);
    };
    this._em.addListener(VALE_USER_EVENT, _listener);
    return () => {
      this._em.removeListener(VALE_USER_EVENT, _listener);
    };
  }

  async setUser(userId: string) {
    const em = this._em;
    if (userId === this._userId) return;
    
    if ( this._userChannel ) {
      this._userChannel.unsubscribe()
      this._userChannel.detach()
    }

    this._userId = userId;
    if (!userId) return;

    this._userChannel = this._ably.channels.get(`user:${userId}`);
    await this._userChannel.attach()
    await this._userChannel.subscribe((message) => {
      console.log("Ably Received message", message)
      if ( message.name === VALE_USER_EVENT ) {
        
        const event = JSON.parse(message.data) as ValeUserEventData
        em.emit(VALE_USER_EVENT, event);
      }      
    })
  }
}

export default ValeMessaging;
