import React, { useState } from 'react';
import { FOOT_PADDING } from '../../../../molecules/footer';
import { useCartContext } from '../../../../../system/CartProvider';
import axios from 'axios';
import { discountApplied } from './CheckoutBar';

const PromoCodeComponent: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [isValid, setIsValid] = useState<boolean | null>(null); // null means no validation yet

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };


    const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value.toUpperCase());
        setIsValid(null); // Reset validation state when typing
    };

    const cartContext = useCartContext();
    const handleApply = async () => {

        try {
            
            const response = await axios.get('https://api-dev.valenow.com/loyalty/checkPromocode', {
                params: {
                    promocode: promoCode
                }
            });

            // Check if the promo code is valid based on the API response
            if (response.data && response.status === 200) {
                setIsValid(true);
                
                discountApplied(response.data.discount);
                cartContext?.setPromoCode?.(response.data.code);
                
            } else {
                console.log('response bad');
            }

        } catch (error) {
            setIsValid(false);
        }


    };

    return (
        <div style={styles.container}>
            <div style={styles.topBar} onClick={handleToggle}>
                <span style={styles.topBarText}>HAVE A PROMO CODE?</span>
                <span style={styles.arrow}>{isExpanded ? '▼' : '▲'}</span>
            </div>

            {isExpanded && (
                <div style={styles.expanded}>

                    <center>
                        <div style={styles.expandedHolder}>

                            <input
                                style={styles.input}
                                type="text"
                                placeholder="Enter code"
                                value={promoCode}
                                onChange={handlePromoCodeChange}
                            />
                            <button style={styles.button} onClick={handleApply}>APPLY</button>

                        </div>
                    </center>

                </div>
            )}
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
      position: 'fixed',
      width: '100%',
      backgroundColor: '#EE72F1',
      fontFamily: 'Inter, BN Dime Display',
      border: '2px solid black',
      bottom: FOOT_PADDING,
    },
    topBar: {
      backgroundColor: '#EE72F1',
      color: '#2C2C2C',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      fontFamily: 'BN Dime Display',
      // borderBottom: '2px solid black',
    },
    topBarText: {
      fontSize: '20px',
      fontWeight: 500,
      padding: '10px 20px',
    },
    arrow: {
      fontSize: '20px',
      padding: '10px 20px',
    },
    expanded: {
      width: '100%',
      backgroundColor: '#EE72F1',
      borderTop: '2px solid black',
    },
    expandedHolder: {
      width: '100%',
      marginTop: '20px',
    },
    input: {
      padding: '15px',
      border: '2px solid black',
      borderRight: 'none',
      fontSize: '18px',
      outline: 'none',
      color: '#D787FF',
      backgroundColor: '#FFF',
      width: '50%',
      borderRadius: '10px 0px 0px 10px',
      marginBottom: '20px',
      textTransform: 'uppercase',
    },
    button: {
      padding: '15px 20px',
      backgroundColor: '#FFEA56',
      color: 'black',
      fontSize: '18px',
      fontWeight: 700,
      cursor: 'pointer',
      outline: 'none',
      borderRadius: '0px 10px 10px 0px',
    },
  };

export default PromoCodeComponent;
