import React, { useCallback, useMemo } from "react";
import Page from "../../../../templates/Page";
import { OrderStackParamList } from "../../../order/order_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { HEADER_HEIGHT } from "../../../../molecules/ValeHeader";
import { useCartContext } from "../../../../../system/CartProvider";
import { useNoSessionContext } from "../../../../../system/NoSessionProvider";
import { findMenuItem } from "../../../../utils/orderItemUtils";
import { getOverrideImage } from "./menu_overrides";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import { TextField } from "@mui/material";

type Props = {} & NativeStackScreenProps<OrderStackParamList, "cart_item_edit">
const CartEditNamePage: React.FC<Props> = (props) => {
    const cart = useCartContext();
    const noSessionInfo = useNoSessionContext();
    
    const onBack = useCallback(() => {
        if ( props.navigation.canGoBack() ) {
            props.navigation.goBack();
        } else {
            props.navigation.navigate("cart", {})
        }
    }, [])



    const info = useMemo(() => {
        
        const item = cart?.items?.find((item) => item.clientItemId === props.route?.params?.clientItemId)
        const menuItem = findMenuItem(noSessionInfo?.menuItems, item?.itemTypeId!)
        const imageUrl = getOverrideImage(menuItem?.name) ?? menuItem?.imageUrl;
        const info = {
            customerName: item?.customerName,
            imageUrl,
            itemName: menuItem?.name!,
        }
        return info

    },[noSessionInfo?.menuItems])
    const [name, setName] = React.useState(info.customerName);
    const clientItemId = props.route?.params?.clientItemId
    const onSet = useCallback(() => {
        
        cart?.updateItem?.({
            clientItemId: clientItemId,
            customerName: name
        })
        
        onBack();
    }, [name, clientItemId, onBack, cart?.updateItem])

    return <Page containerStyle={{
        backgroundColor: "#DECDFF", height: "100%"
    }}>
        <div style={{marginLeft: "16px", marginRight: "16px", marginTop: "36px"}}>
            <div onClick={onBack}>
                <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.377 1L2 11L11.377 21" stroke="#2C2C2C" strokeWidth="2"/>
                    <path d="M24 12C24.5523 12 25 11.5523 25 11C25 10.4477 24.5523 10 24 10V12ZM2 12H24V10H2V12Z" fill="#2C2C2C"/>
                </svg>
            </div>
            <div style={{marginLeft: "16px"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    
                    <div style={{
                        color: '#E30A8F',
                        fontSize: 42,
                        fontFamily: 'BN Dime Display',
                        fontWeight: '400',
                    }}>
                        {info.itemName?.toUpperCase()}
                    </div>
                    <div style={{width: 50, height: 100, position: 'relative'}}>
                        <img src={info.imageUrl} style={{width: '100%', height: '100%', objectFit: "contain", position: 'absolute', top: 0, left: 0}} /> 
                    </div>
                </div>
                <div>
                    <div style={{color: '#4712B1', fontSize: 14, fontFamily: 'Inter', fontWeight: '500'}}>This drink is for:</div>
                    <div style={{position: "relative"}}>
                    <TextField fullWidth variant="standard" value={name} onChange={(e) => setName(e.target.value)}
                    sx={{
                      color: '#E30A8F',
                      fontSize: 18,
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      '& .MuiInput-underline:before': {
                        borderBottom: "2px solid #F87BD3"
                        },
                    '& .MuiInput-underline:after': {
                        borderBottom: "2px solid #E30A8F" 
                    },
                    '& .MuiInputBase-input': {
                        color: '#E30A8F', // Change the font color of the input text
                        fontSize: 18,
                        fontFamily: 'Inter',
                        fontWeight: '500'
                      },    
                  }}
                    />
                    <div onClick={()=> setName("")} style={{position:"absolute", top: 14,right: 0}}><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="7" fill="#EE72F1"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.42217 7.03876L4.37462 9.53005L5.07988 10L7.05291 7.59937L9.38777 9.6746L10 9.11413L7.57755 6.96104L9.62493 4.46995L8.91967 4L6.9468 6.40043L4.61223 4.32545L4 4.88592L6.42217 7.03876Z" fill="white"/>
                    </svg>
                    </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: 24, display:"flex", justifyContent:"center"}}>
                <ValeLaunchButton onPress={onSet} width={155}>SAVE IT</ValeLaunchButton>
            </div>
        </div>
    </Page>
}

export default CartEditNamePage;