import React, { useState } from 'react';
import { amountToString } from '../../utils/stringConversion';

interface ItemInfoSummaryProps {
  pricePerItemCents: number;
  itemName?: string;
  customerNames: (string|undefined)[];
}

function imageFromTitle(title: string) {
  var imagesrc = title.toLowerCase() + ' small.png';
  var fullurl = "/images/menu/" + imagesrc;
  return fullurl;
}

const ItemInfoSummary: React.FC<ItemInfoSummaryProps> = ({ itemName, customerNames, pricePerItemCents }) => {
  const [imgSrc, setImgSrc] = useState(imageFromTitle(itemName!));

  const handleError = () => {
    setImgSrc('/images/menu/cold small.png');
  };

  return (
    
      <div style={cardStyle}>
        <img src={imgSrc} alt={itemName} style={imageStyle} onError={handleError} />
        <div style={contentStyle}>
          <div style={headerStyle}>
            <div>
              <span style={titleStyle}>{itemName?.toUpperCase()}</span>
            </div>
            <div style={priceContainerStyle}>
              <span style={quantityStyle}>{customerNames.length}x</span>
              <span style={priceStyle}>{amountToString(pricePerItemCents)}</span>
            </div>
          </div>
          <div style={nameContainerStyle}>
            {customerNames.map((name, index) => (
              <div style={nameStyle} key={index}>- For {name}</div>
            ))}
          </div>
        </div>
      </div>

    
  );
};

const cardStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px',
  //margin: '10px auto',
};

const imageStyle: React.CSSProperties = {
  width: '60px',
  //height: 'auto',
  //borderRadius: '8px',
  marginRight: '10px',
};

const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

const contentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  marginTop: '10px',
};

const titleStyle: React.CSSProperties = {
  fontSize: '26px',
  fontFamily: 'BN Dime Display',
  fontWeight: 400,
  color: '#E30A8F',
};

const priceContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const quantityStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: 600,
  color: '#2C2C2C',
  marginRight: '10px',
  fontFamily: "Inter",
};

const priceStyle: React.CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#E30A8F',
  fontFamily: "Inter",
};

const nameContainerStyle: React.CSSProperties = {
  marginTop: '10px',
  marginBottom: '16px',
}

const nameStyle: React.CSSProperties = {
  // - For Shawn
  color: '#4712B1',
  fontSize: "14px",
  fontFamily: 'Inter',
  fontWeight: '500',
}

export default ItemInfoSummary;
