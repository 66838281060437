import { Money } from "../../vale_common";

export const stringToPrice = (price: string) => {
  const cost = (Number(price) / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return cost;
};

export const amountToString = (amount: Number | undefined) => {
  
  if (amount !== 0 && !amount) return undefined;

  const cost = (Number(amount) / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
  
  return cost;
}

export const moneyToPrice = (money: Money | undefined) => {
  if (!money) return undefined;
  const cost = (Number(money.amount) / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: money.currency,
  });

  return cost;
};
