type ValeEnvironment = {
  type: "prod" | "dev" | "localhost";
  apiUrl: URL;
  labelApiUrl: URL
  squareAppId: string;
  squareDefaultLocationId: string;
  publicAssetUrl: string;
  firebaseConfig: {
    apiKey?: string;
    authDomain?: string;
    databaseURL?: string;
    projectId?: string;
    storageBucket?: string;
    messagingSenderId?: string;
    appId?: string;
    measurementId?: string;
    webPushKey?: string
  };
};

const DEV_PUBLIC_ASSERTS_URL = "https://storage.googleapis.com/vale_web_assets_dev"
const PROD_PUBLIC_ASSERTS_URL = "https://storage.googleapis.com/vale_web_assets_prod"

const LOCAL_LABEL_URL = "http://localhost:3001";
const LOCAL_SERVER_URL = "http://localhost:8080";

const DEV_LABEL_URL = "https://label-dev.valenow.com";
const DEV_SERVER_URL = "https://api-dev.valenow.com";

const PROD_LABEL_URL =  "https://label.valenow.com";
const PROD_SERVER_URL = "https://apiv1.valenow.com";

const isHostnameDev = location.hostname === "app-dev.valenow.com";

const SQUARE_LOCATION_ID_SANDOBX = "L3YRGP58WN73P";
const SQUARE_APPLICATION_ID_SANDOBX = "sandbox-sq0idb-jtvrwqtzN42LFed5qAlnaA";

const SQUARE_LOCATION_ID_PROD = "LND44CAPXFQBP";
const SQUARE_APPLICATION_ID_PROD = "sq0idp-yKRtaOnyTEYJ6dcq0gk2jQ";

const firebaseConfigDev = {
  apiKey: "AIzaSyAdOdpU1YZwLiqCcS4mZ5RCLFhmNtF-Qtk",
  authDomain: "coffee-c59f9.firebaseapp.com",
  projectId: "coffee-c59f9",
  storageBucket: "coffee-c59f9.appspot.com",
  messagingSenderId: "614835061230",
  appId: "1:614835061230:web:7dbcfb51000fc0929d4eff",
  measurementId: "G-6M4EK3RBX7",
  webpushKey: "BDwKJWUfr5pTIZysDkwKn4QwxBJyX7RXoAO5KN-DK3p4WDJGZe14j1kxdNsiw99ZlErSOIPy49WuyjJ_5C5bXrk"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAU-_KSXyS24O-31kFxEFGY45ccfq2nWoU",
  authDomain: "vale-prod-9407e.firebaseapp.com",
  projectId: "vale-prod-9407e",
  storageBucket: "vale-prod-9407e.appspot.com",
  messagingSenderId: "347761079914",
  appId: "1:347761079914:web:85d6fefb9c3f1c80387c13",
  measurementId: "G-9DWVR04D95",
};

const VALE_PROD_ENV: ValeEnvironment = {
  type: "prod",
  apiUrl: new URL(PROD_SERVER_URL),
  labelApiUrl:  new URL(PROD_LABEL_URL),
  firebaseConfig: firebaseConfigProd,
  squareAppId: SQUARE_APPLICATION_ID_PROD,
  squareDefaultLocationId: SQUARE_LOCATION_ID_PROD,
  publicAssetUrl: PROD_PUBLIC_ASSERTS_URL
};

const VALE_DEV_ENV: ValeEnvironment = {
  type: "dev",
  apiUrl: new URL(DEV_SERVER_URL),
  labelApiUrl:  new URL(DEV_LABEL_URL),
  firebaseConfig: firebaseConfigDev,
  squareAppId: SQUARE_APPLICATION_ID_SANDOBX,
  squareDefaultLocationId: SQUARE_LOCATION_ID_SANDOBX,
  publicAssetUrl: DEV_PUBLIC_ASSERTS_URL
};

const VALE_LOCAL_ENV: ValeEnvironment = {
  type: "localhost",
  apiUrl: new URL(LOCAL_SERVER_URL),
  labelApiUrl:  new URL(DEV_LABEL_URL),
  firebaseConfig: firebaseConfigDev,
  squareAppId: SQUARE_APPLICATION_ID_SANDOBX,
  squareDefaultLocationId: SQUARE_LOCATION_ID_SANDOBX,
  publicAssetUrl: DEV_PUBLIC_ASSERTS_URL
};

const _env =  __DEV__
  ? VALE_DEV_ENV
  : isHostnameDev
    ? VALE_DEV_ENV
     : VALE_PROD_ENV;

export const getEnv = (): ValeEnvironment => _env;
