import React, { useEffect, useMemo } from "react";
import Page from "../../templates/Page";
import { PageTitle } from "../../atoms/TextStyles";
import { useValeContext } from "../../../system/ValeSystem";
import _ from "lodash";

import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import PagesParamList from "../pages_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNavigation, useRoute } from "@react-navigation/native";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

import Confetti from "react-confetti";
import {
  useGetActiveOrders,
  useGetCompletedOrders,
} from "../../hooks/orderHooks";
import { HomePageTileProps } from "./molecules/home_page_tile_types";
import { useGoToCheckoutFlow } from "../checkout/checkout_nav_utils";
import { useTimeToRender } from "../../hooks/perfHooks";
import { useNoSessionContext } from "../../../system/NoSessionProvider";
import { useSessionContext } from "../../../system/SessionProvider";
import { Box, Typography } from "@mui/material";
import Footer from "../../molecules/footer";
import GoAgainComp from "./GoAgainComp";
import PlacingOrder from "./PlacingOrder";
import ScrollingText from "../../molecules/ScrollingText";
import ValeOrderReceipt from "../../molecules/ValeOrderReceipt";
import { Components } from "../../../vale_common";

type OrderReadyHeaderProps = {
  orders?: Components.Schemas.Order[]
}

const OrderReadyHeader: React.FC<OrderReadyHeaderProps> = (props) => {
  const session = useSessionContext();
  const user = session?.userData?.userRecord;


  const orders = useMemo(() => {
    if ( !props.orders || props.orders?.length === 0) return null;
    //List is already sorted

    //ToDo Should we return a list of orders completed recently?, not just the first.
    return <ValeOrderReceipt order={ props.orders[0]}/>
  } ,[props.orders])

  let body = <>
        <Confetti recycle={false} numberOfPieces={500} />

        <div>
          
        <div style={{overflow: 'hidden', width:'100%', display: 'flex', justifyContent: 'center'}}>
          <video id="heroVideo" width="100%" autoPlay playsInline loop muted poster="https://storage.googleapis.com/vale_scratch_prod/order_comp.jpg">
            <source src="https://storage.googleapis.com/vale_scratch_prod/order_comp.mp4" type="video/mp4"/>    
          </video>
        </div>

          <PageTitle style={{maxWidth:304,width:304, marginTop:15, marginBottom:20}}>BOOM!<br/>YOUR ORDER<br/>IS READY</PageTitle>
          <div style={{margin: "auto", display: "flex", justifyContent: "center"}}>
            {orders}
          </div>
          <Box
            width="85%"
            border="2px solid black"
            style={{margin: "auto", marginTop: 40}}
          >
            

            <Typography style={{whiteSpace: "nowrap", overflow:"hidden",fontSize: 23, margin:"20px 0", letterSpacing:6}}>&nbsp;👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋  👋 </Typography>
            <ScrollingText text={`* HEY ${user?.displayName} WHAT'S UP * HEY ${user?.displayName} WHAT'S UP* HEY ${user?.displayName} WHAT'S UP`} /><br/>
            <video autoPlay playsInline loop muted src="https://storage.googleapis.com/vale_scratch_prod/surfer.mp4" width="100%"/>

            <Typography style={{marginTop: 20, marginBottom: 20,fontSize:18, fontFamily:"inter", fontWeight:500, color: "white", textAlign:"center"}}>It's cool you stopped by!<br/><br/>Everyday we're getting better. If<br/>you have anything you wanna say<br/>or have a cool idea, drop us a line.</Typography>

            <center><ValeLaunchButton
              width={155}
              height={55}
              fontStyle={{fontSize:18}}
              onPress={()=> {
                window.location.href = 'https://valenow.com/contact.php';
              }}
            >
              CONTACT US
            </ValeLaunchButton></center>

            <div style={{height: 30, width: "100%"}}>&nbsp;</div>

          </Box>
            


        </div>

      </>
    

  return body
};

export default OrderReadyHeader;
