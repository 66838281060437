export const ToggleOff =  <div style={{display:"inline-block",  position: 'relative'}}><svg width="56" height="33" viewBox="0 0 56 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M17.7898 1.09215C19.6321 0.992152 21.4724 1.00015 23.3147 1.00015C23.3274 1.00015 32.6504 1.00015 32.6504 1.00015C34.5287 1.00015 36.3689 0.992152 38.2102 1.09215C39.8842 1.18215 41.5148 1.37415 43.138 1.80315C46.5548 2.70514 49.5386 4.58913 51.6954 7.26012C53.8395 9.91411 55 13.1631 55 16.4991C55 19.8391 53.8395 23.086 51.6954 25.74C49.5386 28.41 46.5548 30.295 43.138 31.197C41.5148 31.626 39.8842 31.817 38.2102 31.908C36.3689 32.008 34.5287 31.999 32.6864 31.999C32.6736 31.999 23.3486 32 23.3486 32C21.4724 31.999 19.6321 32.008 17.7898 31.908C16.1168 31.817 14.4862 31.626 12.8631 31.197C9.44624 30.295 6.46247 28.41 4.30565 25.74C2.16153 23.086 1 19.8391 1 16.5001C1 13.1631 2.16153 9.91411 4.30565 7.26012C6.46247 4.58913 9.44624 2.70514 12.8631 1.80315C14.4862 1.37415 16.1168 1.18215 17.7898 1.09215Z" fill="#2C2C2C" stroke="#2C2C2C" strokeWidth="2"/>
</svg>
<span style={{position: 'absolute', left: 5, top: 2, fontSize: 24}}>😴</span>
</div>

export const ToggleOn = <div style={{display:"inline-block", position: 'relative'}}><svg width="56" height="33" viewBox="0 0 56 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M17.7898 1.09215C19.6321 0.992152 21.4724 1.00015 23.3147 1.00015C23.3274 1.00015 32.6504 1.00015 32.6504 1.00015C34.5287 1.00015 36.3689 0.992152 38.2102 1.09215C39.8842 1.18215 41.5148 1.37415 43.138 1.80315C46.5548 2.70514 49.5386 4.58913 51.6954 7.26012C53.8395 9.91411 55 13.1631 55 16.4991C55 19.8391 53.8395 23.086 51.6954 25.74C49.5386 28.41 46.5548 30.295 43.138 31.197C41.5148 31.626 39.8842 31.817 38.2102 31.908C36.3689 32.008 34.5287 31.999 32.6864 31.999C32.6736 31.999 23.3486 32 23.3486 32C21.4724 31.999 19.6321 32.008 17.7898 31.908C16.1168 31.817 14.4862 31.626 12.8631 31.197C9.44624 30.295 6.46247 28.41 4.30565 25.74C2.16153 23.086 1 19.8391 1 16.5001C1 13.1631 2.16153 9.91411 4.30565 7.26012C6.46247 4.58913 9.44624 2.70514 12.8631 1.80315C14.4862 1.37415 16.1168 1.18215 17.7898 1.09215Z" fill="#FF00B8" stroke="#2C2C2C" strokeWidth="2"/>
</svg>
<span style={{position: 'absolute', right: 5, top: 2, fontSize: 24}}>🤩</span>
</div>
