import { NavigationProp, useNavigation } from "@react-navigation/native";
import ValeParamList from "../components/pages/pages_param_list";
import { StackNavigationProp } from "@react-navigation/stack";

export const useValeNavigation = useNavigation<NavigationProp<ValeParamList>>;
export const useValeStackNavigation = useNavigation<
  StackNavigationProp<ValeParamList>
>;

export const useShowErrorModal = (_errorMsg: string) => {
  const navigation = useValeNavigation();
  return (message: string) => navigation.navigate("error_modal", { message });
};

export const useShowExceptionModal = () => {
  const navigation = useValeNavigation();
  return (_exception: any) =>
    navigation.navigate("error_modal", {
      message: _exception.message ?? "Unkonwn Error",
    });
};
