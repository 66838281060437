import { useState } from "react";
import { View } from "../../Themed";
import { StyleSheet, TextInput } from "react-native";
import { Text } from "react-native-paper";

export type Props = {
  onChange: (phoneNumber: string) => void;
  autoFocus?: boolean;
};

type DigitProps = {
  number: string;
  index: number;
  isFocused: boolean;
};
const Digit: React.FC<DigitProps> = ({ number, index, isFocused }) => {
  const className = isFocused && number?.length === index ? "blink" : undefined;
  return (
    <text className={className} style={styles.digit_text}>
      {number[index] ?? "-"}
    </text>
  );
};
const ConfirmCodeInput = (props: Props) => {
  const [number, setNumber] = useState<any | undefined>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  // const oldInput = (
  //   <>
  //     {" "}
  //     <TextInput
  //       autoFocus={props.autoFocus}
  //       value={number}
  //       inputMode="numeric"
  //       maxLength={6}
  //       style={{
  //         position: "absolute",
  //         opacity: 0,
  //         width: "100%",
  //         height: "100%",
  //       }}
  //       onFocus={() => setIsFocused(true)}
  //       onBlur={() => setIsFocused(false)}
  //       onChange={(value) => {
  //         const newValue = value?.nativeEvent?.text || "";
  //         setNumber(newValue);
  //         props.onChange(newValue);
  //       }}
  //     />
  //     <Digit number={number} index={0} isFocused={isFocused} />
  //     <Digit number={number} index={1} isFocused={isFocused} />
  //     <Digit number={number} index={2} isFocused={isFocused} />
  //     <Digit number={number} index={3} isFocused={isFocused} />
  //     <Digit number={number} index={4} isFocused={isFocused} />
  //     <Digit number={number} index={5} isFocused={isFocused} />
  //   </>
  // );
  return (
    <input
      type="number"
      style={{
        color: "#250D56",
        fontSize: 33,
        fontFamily: "Inter",
        fontWeight: "400",
        letterSpacing: 0.25,
        textAlign: "center",
      }}
      autoFocus={true}
      onChange={(e) => {
        const newValue = e.target.value;
        setNumber(newValue);
        props.onChange(newValue);
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: "100%",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  gap: {
    width: 16,
  },
  digit_text: {
    color: "#250D56",
    fontSize: 33,
    fontFamily: "Inter",
    fontWeight: "400",
    letterSpacing: 0.25,
    marginLeft: 4,
  },
});

export default ConfirmCodeInput;
