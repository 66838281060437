import { Components } from "../../../vale_common"
import ScrollingText from "../../molecules/ScrollingText"

type PlacingOrderHeaderProps = {
  orders?: Components.Schemas.Order[]
}

const PlacingOrderHeader: React.FC<PlacingOrderHeaderProps> = ({orders}) => {
    return  <div><div style={{overflow: 'hidden', width:'100%', display: 'flex', justifyContent: 'center'}}>
    <video id="heroVideo" width={"100%"} autoPlay playsInline loop muted poster="https://storage.googleapis.com/vale_scratch_prod/agassi-loading.jpg">
      <source src="https://storage.googleapis.com/vale_scratch_prod/agassi.mp4" type="video/mp4"/>    
    </video>
  </div>

  <div style={{marginTop: 40, textAlign:"center", color: "#2C2C2C", fontFamily: "BN Dime Display", fontSize: 40}}>WORKING ON<br/>YOUR ORDER...</div>
  <div style={{marginBottom: 140, marginTop: 12, textAlign:"center", color: "white", fontWeight: 500, fontFamily: "Inter", fontSize: 20}}>Thank you! We'll text you<br/>when its ready.</div>
  <ScrollingText text="👇👇👇 WHILE YOU'RE WAITING...👇👇👇 WHILE YOU'RE WAITING..." />
  </div>
}

export default PlacingOrderHeader