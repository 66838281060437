import React, { useMemo } from "react";
import Page from "../../templates/Page";
import { useGoToHome, useGoToStartOrder } from "../landing/landing_page_utils";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import LandingPageClosed from "../landing/LandingPageClosed";
import PagesParamList from "../pages_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNoSessionContext } from "../../../system/NoSessionProvider";
import { getStoreStatus } from "../../../system/menu/menuUtils";
import { Box, Link, Typography } from "@mui/material";
import { PageTitle } from "../../atoms/TextStyles";
import Footer from '../../molecules/footer';


function fix12Hour(time: string | undefined) {
    if (!time) {
      return;
    }
    var hourStr = time.split(":")[0];
    var minsStr = time.split(":")[1];
    const hour = parseInt(hourStr);
    if (hour <= 12) {
      //return parseInt(hourStr) + ":" + minsStr + "AM";
      return parseInt(hourStr) + "AM";
    }
  
    var newtime = hour - 12;
    //return newtime + ":" + minsStr + "PM";
    return newtime + "PM";
}

function splitWeek(item:any, weekend:boolean = false) {

    if(!weekend) {
        if(item.dayOfWeek == "SUN" || item.dayOfWeek == "SAT") {
            return;
        } 

        return <HourRow name={longName(item.dayOfWeek)} time={fix12Hour(item.startLocalTime) + "-" + fix12Hour(item.endLocalTime)} key={item.dayOfWeek} />
    }

    if(item.dayOfWeek == "SUN" || item.dayOfWeek == "SAT") {
        return <HourRow name={longName(item.dayOfWeek)} time={fix12Hour(item.startLocalTime) + "-" + fix12Hour(item.endLocalTime)} key={item.dayOfWeek} />
    } else {
        return;
    }

}

function longName(day:string) {
    var long = '';
    if(day == "SUN")
        long = "SUNDAY";
    if(day == "MON")
        long = "MONDAY";
    if(day == "TUE")
        long = "TUESDAY";
    if(day == "WED")
        long = "WEDNESDAY";
    if(day == "THU")
        long = "THURSDAY";
    if(day == "FRI")
        long = "FRIDAY";
    if(day == "SAT")
        long = "SATURDAY";
    
    return long;
}


const HoursPage = () => {
  
  const goToHome = useGoToHome();
  const noSessionInfo = useNoSessionContext();
  const hours = noSessionInfo?.locations?.[0]?.businessHours;

  console.log(hours);
  
    return (
      <Page>
        <div style={{overflow: 'hidden', height: 347, width:'100%', display: 'flex', justifyContent: 'center'}}>
          <video id="heroVideo" height="347" autoPlay playsInline loop muted poster="https://storage.googleapis.com/vale_scratch_prod/hours-loading.jpg">
            <source src="https://storage.googleapis.com/vale_scratch_prod/hourshero.mp4" type="video/mp4"/>    
          </video>
        </div>
        
        <PageTitle style={{maxWidth:304,width:304}}>BUSINESS HOURS</PageTitle>

        <div style={{color:"white",
            fontSize: 14, 
            textAlign:"center",
            margin: "auto",
            padding: 10
            }}>

            <Typography style={{fontWeight:"600"}}>👋 Our hours can change depending on
                <br/>where we are and what we're doing.
                <br/>Check back often as we keep this page
                <br/>updated daily. Thx!
            </Typography>
        </div>
        

        <Box
            style={{backgroundColor: "#EE72F1", width:"90%", margin: "auto", border:"2px solid #2C2C2C", borderRadius: 10, marginTop: 20, marginBottom: 80}}>


            <PageTitle style={{}}>🏃‍♀️ WEEKDAYS</PageTitle>

            <div style={scheduleStyle}>
                {hours?.map((item:any) => (

                    splitWeek(item)
                        
                ))}
            </div>
            
            <PageTitle style={{}}>🏄 WEEKENDS</PageTitle>

            <div style={scheduleStyle}>
            {hours?.map((item:any) => (

                splitWeek(item, true)
                
            ))}
            </div>


        </Box>
      


        <Footer />

      </Page>
    );
  }

  const scheduleStyle: React.CSSProperties = {
    fontFamily: "Inter",
    padding: '20px',
  };

interface HourRowProps {
    name: string;
    time: any;
}

function fixDots(name:string) {
    console.log(name)
    if(name == 'WEDNESDAY')
        return '...........'
    else 
        return '....................'
}

const HourRow: React.FC<HourRowProps> = ({ name, time }) => {
    return (
        <div style={rowStyle}>
            <span style={dayStyle}>{name}</span>
            <span style={dotsStyle}>{fixDots(name)}</span>
            <span style={timeStyle}>{time}</span>
        </div>
    );
};

const rowStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 0',
};
  
const dayStyle: React.CSSProperties = {
    fontFamily: "BN Dime Display",
    fontSize: '20px',
    color: "#2c2c2c",
};

const dotsStyle: React.CSSProperties = {
    flex: 1,
    textAlign: 'center',
};

const timeStyle: React.CSSProperties = {
    fontSize: '18px',
};

export default HoursPage;