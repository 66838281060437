import moment from "moment-timezone";
import { LocationList } from "../../vale_common";

export type StoreStatus = "WILL_OPEN" | "OPEN" | "CLOSED";
export type StoreStatusInfo = {
  storeStatus: StoreStatus;
  storeStatusMessage: string;
};

export const getStoreStatus = (
  locations?: LocationList,
): StoreStatusInfo | undefined => {
  const resp: StoreStatusInfo = {
    storeStatus: "CLOSED",
    storeStatusMessage: "Closed",
  };
  
  if (!locations) return;
  const location = locations[0];
  if (!location) return resp;

  if (!location.timezone) return resp;

  const now = moment();

  const nowTz = now.tz(location.timezone);

  const dayOfWeek = nowTz.format("ddd");

  const matchingDay = location.businessHours?.find(
    (p) => p.dayOfWeek?.toLowerCase() === dayOfWeek?.toLowerCase(),
  );

  if (!matchingDay) return resp;

  const startTime = moment(matchingDay.startLocalTime, "HH:mm:ss");
  const endTime = moment(matchingDay.endLocalTime, "HH:mm:ss");

  const buisnessHoursStart = nowTz.clone().set({
    hour: startTime.hour(),
    minute: startTime.minute(),
    second: startTime.second(),
  });

  const buisnessHoursEnd = nowTz.clone().set({
    hour: endTime.hour(),
    minute: endTime.minute(),
    second: endTime.second(),
  });

  const isBetween = nowTz.isBetween(buisnessHoursStart, buisnessHoursEnd);
  if (isBetween) {
    return {
      storeStatus: "OPEN",
      storeStatusMessage: `Open until ${buisnessHoursEnd.format("h:mm A")}`,
    };
  }

  const isBefore = nowTz.isBefore(buisnessHoursStart);
  if (isBefore) {
    return {
      storeStatus: "WILL_OPEN",
      storeStatusMessage: `Opens at ${buisnessHoursStart.format("h:mm A")}`,
    };
  }

  const isAfter = nowTz.isAfter(buisnessHoursEnd);
  if (isAfter) {
    return {
      storeStatus: "CLOSED",
      storeStatusMessage: `Closed`,
    };
  }

  return resp;
};
