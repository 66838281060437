//  NativeStackScreenProps<CheckoutStackParamList, "phoneNumberEntry">

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import PhoneNumberEntryPage from "../auth/PhoneNumberEntryPage";
import PagesParamList from "../pages_param_list";

type Props = NativeStackScreenProps<PagesParamList, "auth">;
const AuthScreen: React.FC<Props> = (props) => {
  const pageProps = props.route.params;
  return (
    <PhoneNumberEntryPage
      authType={pageProps.type}
      onComplete={async () => {
        const redirectNav = props.route.params.redirectNav;
        const route = JSON.parse(atob(redirectNav)) as {
          name: string;
          params: any;
        };
        //@ts-ignore
        props.navigation.replace(route.name, route.params);
      }}
    />
  );
};

export default AuthScreen;
