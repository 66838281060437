import { Components, MenuItemsMap } from "../../vale_common"
import { getEnv } from "../../system/env";

const env = getEnv();

export const findMenuItem = (menuItems: MenuItemsMap | undefined, id: string) => {
    return menuItems ? menuItems.get(id) : undefined;
}
  
export const getLabelUri = (customerName: string, drinkName: string, labelTemplateNumber: number, labelImageId?: string) => {
    
    const labelApiUrl = new URL(env.labelApiUrl);
    labelApiUrl.pathname = 'genLabelV3'
    labelApiUrl.searchParams.append('customerName', customerName ?? "");
    labelApiUrl.searchParams.append('drinkName', drinkName ?? "");
    labelApiUrl.searchParams.append('templateNumber', labelTemplateNumber.toString());
    if ( labelImageId) {
        labelApiUrl.searchParams.append('labelImageId', labelImageId.toString());
    }
    labelApiUrl.searchParams.append('quality', "fast");

    return labelApiUrl.toString();
}

export const preLoadLabelImage = (customerName: string, drinkName: string, labelTemplateNumber: number,  labelImageId?: string) => {
    const preloadImage = new Image();

    preloadImage.src = getLabelUri(customerName, drinkName, labelTemplateNumber, labelImageId);

    // Optionally, you can add an event listener to confirm when the image is loaded
    preloadImage.onload = () => {
        console.log('Image preloaded : ', customerName, drinkName, labelTemplateNumber);
    };
}