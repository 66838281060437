// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import ScrollingText from './ScrollingText';
import { useValeStackNavigation } from '../../nav/nav_utils';

export const FOOT_PADDING = "82px";
const Footer = () => {
  const navigation = useValeStackNavigation();

    const FooterItem = (props: any) => {
        return <Box><Typography
        variant="h3"
        onClick={props.onClick}
        style={{color:"white"}}
      >
        {props.children}
      </Typography></Box>
      }
      
  return (
    <div style={{paddingBottom: FOOT_PADDING, backgroundColor: "#E30A8F"}}>
    <Box
      width="100%"
      height={37}
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: "#E652EC",
        borderTop: '1px solid',
        borderBottom: '1px solid',
      }}
    >
      {/* Maybe https://marqueefy.github.io/#options */}

      <ScrollingText text="CHEF-CURATED * SCRATCH-MADE SYRUPS * DELICIOUS FLAVORS * " />
    </Box>

    <Box>
    <Box style={{display: 'flex', marginTop: 17, marginLeft: 24, marginRight: 24, justifyContent: 'space-between'}}>
        <Box>
        
          <FooterItem onClick={()=>{
            navigation.navigate("order", {screen: "selection"});
          }}>Menu</FooterItem>
        
        
         <FooterItem onClick={()=>{
            navigation.navigate("hours");
          }}>Hours</FooterItem>
       
       
        <FooterItem onClick={()=>{
            navigation.navigate("about");
          }}>About</FooterItem>
       
       <FooterItem onClick={()=>{
            navigation.navigate("contact");
          }}>Contact Us</FooterItem>
       
        <a href="https://www.valenow.com/privacy.html" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}>
          <FooterItem>Privacy Policy</FooterItem>
        </a>

        <a href="https://www.valenow.com/terms.html" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}>
          <FooterItem>Terms & Conditions</FooterItem>
        </a>
        
        
        
        </Box>
        <Box>
        <img src="/cuppy.png" width={100} height={106}/>
        <Box display={'flex'} justifyContent={'space-around'}>
            
            <a href="https://www.instagram.com/VALEGONOW" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.5" cy="16.5" r="16.5" fill="#F64AC3"/>
              <path d="M21.5941 6H10.5389C7.48468 6 5 8.48684 5 11.5437V21.6665C5 24.7233 7.48468 27.2102 10.5389 27.2102H21.5941C24.6484 27.2102 27.133 24.7233 27.133 21.6665V11.5437C27.133 8.48684 24.6484 6 21.5941 6ZM6.95395 11.5437C6.95395 9.56552 8.56241 7.95563 10.5389 7.95563H21.5941C23.5706 7.95563 25.1791 9.56552 25.1791 11.5437V21.6665C25.1791 23.6446 23.5706 25.2545 21.5941 25.2545H10.5389C8.56241 25.2545 6.95395 23.6446 6.95395 21.6665V11.5437Z" fill="#2C2C2C"/>
              <path d="M16.0662 21.7604C18.9063 21.7604 21.2183 19.4477 21.2183 16.6038C21.2183 13.7599 18.9076 11.4472 16.0662 11.4472C13.2248 11.4472 10.9141 13.7599 10.9141 16.6038C10.9141 19.4477 13.2248 21.7604 16.0662 21.7604ZM16.0662 13.4041C17.8299 13.4041 19.2643 14.8398 19.2643 16.605C19.2643 18.3703 17.8299 19.806 16.0662 19.806C14.3025 19.806 12.868 18.3703 12.868 16.605C12.868 14.8398 14.3025 13.4041 16.0662 13.4041Z" fill="#2C2C2C"/>
              <path d="M21.6948 12.2803C22.4596 12.2803 23.083 11.6577 23.083 10.891C23.083 10.1242 22.4609 9.50159 21.6948 9.50159C20.9287 9.50159 20.3066 10.1242 20.3066 10.891C20.3066 11.6577 20.9287 12.2803 21.6948 12.2803Z" fill="#2C2C2C"/>
              </svg>
            </a>

            <a href="https://www.tiktok.com/@valegonow" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}>
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.5" cy="16.5" r="16.5" fill="#F64AC3"/>
              <path d="M24.9571 11.7432V15.1195C24.3676 15.0619 23.6015 14.9278 22.7541 14.6171C21.6476 14.2112 20.8239 13.6562 20.2844 13.2265V20.0506L20.2707 20.0293C20.2794 20.1646 20.2844 20.3024 20.2844 20.4415C20.2844 23.8303 17.5294 26.5891 14.1422 26.5891C10.7551 26.5891 8 23.8303 8 20.4415C8 17.0526 10.7551 14.2927 14.1422 14.2927C14.4739 14.2927 14.7994 14.319 15.1173 14.3703V17.6978C14.8119 17.5888 14.4839 17.5299 14.1422 17.5299C12.5388 17.5299 11.2332 18.8354 11.2332 20.4415C11.2332 22.0476 12.5388 23.353 14.1422 23.353C15.7457 23.353 17.0512 22.0463 17.0512 20.4415C17.0512 20.3813 17.05 20.3212 17.0462 20.2611V7H20.4171C20.4296 7.28564 20.4409 7.5738 20.4534 7.85945C20.476 8.42196 20.6762 8.96193 21.0255 9.40418C21.4348 9.9241 22.0394 10.5279 22.888 11.0103C23.6829 11.46 24.4289 11.6542 24.9571 11.7457V11.7432Z" fill="#2C2C2C"/>
              </svg>
            </a>


        </Box>
        </Box>
    </Box>
    <Typography style={{color: 'white', textAlign: "center", fontSize: 9, fontFamily: 'Inter', fontWeight: '400', lineHeight: "23px"}}>© copyright 2024 Vale Concepts</Typography>
    </Box>
    
   
    </div>
  );
};

export default Footer;
