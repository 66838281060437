import { SessionInfo } from "../../../system/SessionProvider";
import {
  MenuItem,
  MenuItemsMap,
  Money,
  OrderState,
  OrderStateList,
} from "../../../vale_common";

export enum OrderStep {
  ITEM,
  // OPTION,
  // ATTRIBUTES,
  COMPLETE,
}

export const getNextMenuItemStep = (
  menuMap: MenuItemsMap,
  orderUiState?: OrderStep,
  state?: OrderStateList,
  stepId?: string,
): { step: OrderStep; stepId?: string } => {
  if (state && state?.length > 0) return { step: OrderStep.ITEM };

  //const menuItem = itemId ? menuMap.get(itemId) : undefined;
  // const remainingOptions = menuItem?.options?.filter((itemOption) => {
  //   return !state.options?.[itemOption.id!];
  // });

  // if (remainingOptions && remainingOptions.length > 0) {
  //   return { step: OrderStep.OPTION, stepId: remainingOptions[0].id };
  // }

  //return { step: OrderStep.ATTRIBUTES };
  return { step: OrderStep.COMPLETE };
};

export const getMenuItemPrice = (menuItem: MenuItem): Money => {
  return menuItem.variations?.[0].price!;
};

// import { Components } from "../../../vale-openapi";

// type ValeCatalogObject = Components.Schemas.ValeCatalogObject;
// type ValeCatalogTax = Components.Schemas.ValeCatalogTax;
// type ValeMoney = Components.Schemas.ValeMoney;

// export type MenuItem = {
//   name: string | null | undefined;
//   description?: string | null | undefined;
//   id: string | null | undefined;
//   price: ValeMoney | undefined;
//   customAttributeValues: {
//     id: string;
//     type?: string; //"NUMBER" | "STRING" | "SELECTION";
//     numberValue?: string | null;
//     booleanValue?: boolean | null;
//     selectionUidValues?: string[] | null;
//     stringValue?: string | null;
//   }[];
//   variations?: {
//     id: string;
//     itemOptionValues:
//       | {
//           itemOptionId?: string | null;
//           itemOptionValueId?: string | null;
//         }[]
//       | null
//       | undefined;
//   }[];
//   itemOptions?: string[];
//   imageIds: string[] | null | undefined;
//   taxIncluded?: boolean;
// };

// export type DrinkOption = {
//   id: string;
//   name?: string;
//   displayName?: string;
//   values: {
//     id: string; //Not sure why there are two ids for a value
//     itemOptionId: string;
//     name: string;
//   }[];
// };

// export type CustomAttribute = {
//   id: string;
//   name: string;
//   configs?: {
//     uid: string;
//     name: string;
//   }[];
// };

// export type Menu = {
//   orderedMenuItemList: string[];
//   menuItems: Record<string, MenuItem>;
//   itemOptions: Record<string, DrinkOption>;
//   customAttributes: Record<string, CustomAttribute>;
//   imageUrls: Record<string, string>;
// };

// export const parseMenu = (menu: ValeCatalogObject[]): Menu => {
//   /*
//     FYI : We don't currently support modifiers
//   */
//   const orderedMenuItemList: string[] = [];
//   const menuItems: Record<string, MenuItem> = {};
//   const itemOptions: Record<string, DrinkOption> = {};
//   const customAttributes: Record<string, CustomAttribute> = {};
//   const imageUrls: Record<string, string> = {};
//   const taxInfo: Record<string, ValeCatalogTax> = {};

//   //Tax Info
//   menu
//     .filter((item) => item.type === "TAX")
//     .forEach((tax) => {
//       taxInfo[tax.id] = tax.taxData!;
//     });

//   //Menu Items
//   menu
//     .filter((item) => item.type === "ITEM")
//     .filter(
//       (item) =>
//         !(
//           item.itemData?.variations?.[0]?.absentAtLocationIds?.includes(
//             getEnv().squareDefaultLocationId
//           ) === true
//         )
//     )
//     .forEach((item) => {
//       orderedMenuItemList.push(item.id);
//       menuItems[item.id] = {
//         taxIncluded: item.itemData?.taxIds?.[0]
//           ? taxInfo[item.itemData.taxIds[0]].inclusionType === "INCLUSIVE"
//           : false,
//         name: item.itemData?.name,
//         description: item.itemData?.description,
//         id: item.id,
//         price:
//           item.itemData?.variations?.length === 1
//             ? item.itemData?.variations[0].itemVariationData?.priceMoney
//             : undefined,
//         imageIds: item.itemData?.imageIds,
//         customAttributeValues: Object.values(
//           item.customAttributeValues || {}
//         ).map((attribute) => ({
//           id: attribute.customAttributeDefinitionId!,
//           type: attribute.type,
//           numberValue: attribute.numberValue,
//           stringValue: attribute.stringValue,
//           selectionUidValues: attribute.selectionUidValues,
//           booleanValue: attribute.booleanValue,
//         })),
//         itemOptions: item.itemData?.itemOptions?.map(
//           (option) => option.itemOptionId!
//         ),
//         variations: item.itemData?.variations?.map((variation) => ({
//           id: variation.id,
//           itemOptionValues: variation.itemVariationData?.itemOptionValues,
//         })),
//       };
//     });

//   //Item Options
//   menu
//     .filter((item) => item.type === "ITEM_OPTION")
//     .forEach((option) => {
//       itemOptions[option.id] = {
//         id: option.id,
//         name: option.itemOptionData?.name!,
//         displayName: option.itemOptionData?.displayName!,
//         values:
//           option.itemOptionData?.values?.map((value) => ({
//             id: value.id!,
//             itemOptionId: value.itemOptionValueData?.itemOptionId!,
//             name: value.itemOptionValueData?.name!,
//           })) || [],
//       };
//     });

//   //Custom Attributes

//   menu
//     .filter((item) => item.type === "CUSTOM_ATTRIBUTE_DEFINITION")
//     .forEach((attribute) => {
//       const configs =
//         attribute.customAttributeDefinitionData?.selectionConfig?.allowedSelections?.map(
//           (selection) => ({
//             uid: selection.uid!,
//             name: selection.name!,
//           })
//         );
//       customAttributes[attribute.id] = {
//         id: attribute.id,
//         name: attribute.customAttributeDefinitionData?.name!,
//         configs,
//       };
//     });

//   //Image Urls

//   menu
//     .filter((item) => item.type === "IMAGE")
//     .forEach((image) => {
//       imageUrls[image.id] = image.imageData?.url!;
//     });

//   return {
//     orderedMenuItemList,
//     menuItems,
//     itemOptions,
//     customAttributes,
//     imageUrls,
//   };
// };
export const BETA_DISCOUNT_PERCENTAGE = 25;

// export const getDiscountPrice = (
//   money?: Money,
//   sesionInfo?: SessionInfo | null,
// ): Money | undefined => {
//   const discountPercentage = sesionInfo?.userData?.userDoc?.betaProgram
//     ? BETA_DISCOUNT_PERCENTAGE
//     : 0;

//   return money?.amount && discountPercentage && discountPercentage > 0
//     ? {
//         amount: money?.amount * ((100 - discountPercentage) / 100),
//         currency: "USD",
//       }
//     : undefined;
// };

// export const calculateTotalAmount = (
//   sessionInfo: SessionInfo,
//   menu: MenuItemsMap,
//   orders: OrderState[],
// ) => {
//   return orders.reduce(
//     (acc, order) => {
//       const menuItem = menu.get(order.itemId!);
//       const menuItemPrice = getMenuItemPrice(menuItem!);
//       const preDiscountMenuItemAmount = menuItemPrice.amount || 0;

//       const discountMenuItemPrice = getDiscountPrice(
//         menuItemPrice,
//         sessionInfo,
//       );
//       const itemCostAmount =
//         discountMenuItemPrice?.amount || preDiscountMenuItemAmount;

//       return {
//         amount: acc.amount + itemCostAmount,
//         preDiscountAmount: acc.preDiscountAmount + preDiscountMenuItemAmount,
//       };
//     },
//     { amount: 0, preDiscountAmount: 0 },
//   );
// };
