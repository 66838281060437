import React, { useState } from 'react';
import Page from '../../templates/Page';
import { PageTitle } from '../../atoms/TextStyles';
import { Typography, Box } from '@mui/material';
import Footer from '../../molecules/footer';
import ValeLaunchButton from '../../molecules/ValeLaunchButton';
import ContactSuccess from './ContactSuccess';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [msgSent, setMsgSent] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = 'Email is not valid';
    }
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const doSubmit = () => {
    if (msgSent) return;

    scrollToTop();

    if (!validateForm()) {
      return;
    }
    
    setMsgSent(true);

    const button = document.getElementById('submitBtn') as HTMLButtonElement;
    button.innerHTML = '<div class="MuiBox-root css-0" style="background-color: rgb(44, 44, 44); position: absolute; inset: 5px 4px 0px 0px; border-radius: 10px;"></div><div class="MuiBox-root css-0" style="background-color: rgb(255, 0, 184); border-color: rgb(44, 44, 44); border-style: solid; border-width: 2px; position: absolute; inset: 0px 0px 3px 2px; border-radius: 10px; display: flex; align-items: center;"><p class="MuiTypography-root MuiTypography-body1 css-diork5-MuiTypography-root" style="color: rgb(255, 255, 255); text-align: center; width: 100%; font-family: Inter; font-weight: 700; font-size: 18px;">SENT</p></div>';

    handleSubmit()
      .then(() => {
        console.log("Form submission handled successfully");
      })
      .catch((error) => {
        console.error("Error handling form submission", error);
      });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://valenow.com/contactSend.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //todo: fix this bad code and make a component or something
  if(msgSent) {
    return (
      <Page>
        <ContactSuccess />
      </Page>
    )
  } else {
    return (
      <Page>
  
        <PageTitle style={{ maxWidth: 304, width: 304 }}>DROP US<br />A LINE</PageTitle>
        <Typography style={{ margin: "20px 0px", marginBottom: "50px", lineHeight: "1.2em", textAlign: "center", color: "white", fontWeight: "500" }}>Need support, have feedback, or<br />
          planning an event? Reach out, we'd<br />
          love to hear from you. 🏄</Typography>
  
        <form id="contactForm">
          <div style={formHolder}>
            <div style={row}>
              <label style={labelStyle}>
                <p style={titleStyle}>NAME*:</p>
                <input
                  style={errors.name ?  textInputError : textInput }
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder='Bill Murray'
                />
                
                {errors.name && <span style={{position: "absolute", right: 75, marginTop:14}}>
                  <img src="./form_error.png" />
                </span>}

              </label>
              {errors.name && <p style={errorStyle}>{errors.name}</p>}
            </div>
  
            <div style={row}>
              <label style={labelStyle}>
              <p style={titleStyle}>EMAIL*:</p>
                <input
                  style={errors.email ?  textInputError : textInput }
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='bmurray@email.com'
                />

                {errors.email && <span style={{position: "absolute", right: 75, marginTop:14}}>
                  <img src="./form_error.png" />
                </span>}

              </label>
              {errors.email && <p style={errorStyle}>{errors.email}</p>}
            </div>
  
            <div style={row}>
              <label style={labelStyle}>
              <p style={titleStyle}>PHONE*:</p>
                <input
                  style={errors.phone ?  textInputError : textInput }
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder='(941)-555-2368'
                />

                {errors.phone && <span style={{position: "absolute", right: 75, marginTop:14}}>
                  <img src="./form_error.png" />
                </span>}

              </label>
              {errors.phone && <p style={errorStyle}>{errors.phone}</p>}
            </div>
  
            <div style={row}>
              <label style={labelStyle}>
              <p style={titleStyle}>MESSAGE*:</p>
                <textarea
                  style={errors.message ?  textAreaInputError : textAreaInput }
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && <span style={{position: "absolute", right: 75, marginTop:14}}>
                  <img src="./form_error.png" />
                </span>}
              </label>
              {errors.message && <p style={errorStyle}>{errors.message}</p>}
            </div>
  
            <Box width={100} height={45}/>

            <center>
              <ValeLaunchButton
                id='submitBtn'
                width={165}
                height={55}
                fontStyle={{ fontSize: 18 }}
                onPress={doSubmit}
              >
                SUBMIT
              </ValeLaunchButton>
            </center>
          </div>
        </form>
  
        <Footer />
      </Page>
    );
  }

  


};

const formHolder: React.CSSProperties = {
  width: "80%",
  margin: "0px auto",
  marginBottom: 100
};

const row: React.CSSProperties = {
  width: "100%",
};

const titleStyle: React.CSSProperties = {
  
  marginTop: 20,
  marginBottom: 10,

};

const labelStyle: React.CSSProperties = {
  fontFamily: "BN Dime Display",
  fontSize: "22px",
  fontWeight: 500,
  color: "#2C2C2C",
  padding: "5px 0",
};

const textInput: React.CSSProperties = {
  height: "50px",
  borderRadius: "8px",
  border: "3px solid #2c2c2c",
  width: "100%",
  color: "#FF00B8",
  fontSize: 18,
  fontWeight: 500,
  fontFamily: "Inter",
  boxSizing: "border-box",
  paddingLeft: 20
};

const textInputError: React.CSSProperties = {

  border: "3px solid #4712B1",
  height: "50px",
  borderRadius: "8px",
  width: "100%",
  color: "#FF00B8",
  fontSize: 18,
  fontWeight: 500,
  fontFamily: "Inter",
  boxSizing: "border-box",
  paddingLeft: 20,
  boxShadow: "0px 0px 10px rgba(71, 18, 177, .4)",

};

const textAreaInput: React.CSSProperties = {
  height: 300,
  width: "100%",
  borderRadius: "8px",
  border: "3px solid #2c2c2c",
  color: "#FF00B8",
  fontSize: 18,
  fontWeight: 500,
  fontFamily: "Inter",
  boxSizing: "border-box",
  paddingLeft: 20
};

const textAreaInputError: React.CSSProperties = {
  height: 300,
  width: "100%",
  borderRadius: "8px",
  border: "3px solid #4712B1",
  color: "#FF00B8",
  fontSize: 18,
  fontWeight: 500,
  fontFamily: "Inter",
  boxSizing: "border-box",
  paddingLeft: 20
};

const errorStyle: React.CSSProperties = {
  color: '#4712B1',
  fontSize: 16,
  fontWeight: 800,
  fontFamily: "Inter",
  fontStyle: "italic",
  marginTop:5,
  paddingLeft:10
};

export default ContactPage;
