import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useValeContext } from "./ValeSystem";
import { StoreStatusInfo, getStoreStatus } from "./menu/menuUtils";
import { LocationList, MenuItemsList, MenuItemsMap } from "../vale_common";
import axios from 'axios';
import { getEnv } from "./env";

export type NoSessionInfo = { 
  menuItems?: MenuItemsMap;
  locations?: LocationList;
  storeStatusInfo?: StoreStatusInfo;

};

export const NoSessionContext = React.createContext<NoSessionInfo | null>(null);
export const useNoSessionContext = () => useContext(NoSessionContext);

const webAssetUrl = getEnv().publicAssetUrl;
const NoSessionProvider: React.FC<PropsWithChildren> = ({ children }) => {

  const system = useValeContext();
  const [value, setValue] = useState<NoSessionInfo | null>(null);





  useEffect(() => {

    const fetchSessionInfo = async () => {
      
      const url = `${webAssetUrl}/menu_location.json`;
      console.log("url : ", url);
      const response = await axios({
          url,
          method: 'GET',
          responseType: 'json',
      });

      const menu = response.data.menu as MenuItemsList
      const locations = response.data.locations as LocationList
      
      const menuItems = menu
        ? new Map(menu.map((obj) => [obj.id, obj]))
        : new Map();

      const storeStatusInfo = getStoreStatus(locations);
      setValue({
        menuItems,
        locations,
        storeStatusInfo,
      });
    };
    fetchSessionInfo();
    // measurePromiseTime(fetchSessionInfo, "fetchSessionInfo");
  }, [system?.valeClientOpenapi]);

  return (
    <NoSessionContext.Provider value={value}>
      {children}
    </NoSessionContext.Provider>
  );
};

export default NoSessionProvider;
