import React from 'react';

const MyAccountImage: React.FC = () => {
    const containerStyle: React.CSSProperties = {
    position: 'relative',
  };

 
  return (
    <div style={containerStyle}>    
      <img src="./youraccount-hero.jpg" width={"100%"} height={"100%"}/>
    </div>
  );
};

export default MyAccountImage;
