import { Box, Typography } from "@mui/material";
import { useState } from "react";
import PhoneInput from "react-phone-number-input/input";
import { background } from "../../../App";

export type Tagged<A, T> = A & { __tag?: T };
export type E164Number = Tagged<string, "E164Number">;

export type Props = {
  id?: string;
  onChange: (phoneNumber: E164Number | undefined) => void;
  autoFocus?: boolean;
  placeHolder?: string;
};

const PhoneNumberInput = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);
  //const placeHolder = !inputValue && props.placeHolder && !focused ? <Typography style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0}}>{props.placeHolder}</Typography> : null
  
  return (
    <div style={{position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <PhoneInput
        id={props.id}
        //className="ValePhoneInput"
        //country="US"
        defaultCountry="US"
        onChange={(value) => {
          props.onChange(value);
        }}
        
        placeholder={props.placeHolder}
        smartCaret={true}
        autoFocus={true}
        onFocus={() => {setFocused(true)}}
        onBlur={() => {setFocused(false)}}

        style={{
          width: "100%",
          selfAlign: "center",
          maxWidth: 339,
          height: 50,
          fontSize: 18,
          fontFamily: "Inter",
          fontWeight: "500",
          borderColor: "#2C2C2C",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "2px",
          outlineColor: "#FF00B8",
          color: "#FF00B8"
        }}
      />
      
    </div>
  );
};

export default PhoneNumberInput;
