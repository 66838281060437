import React from 'react';
import ValeLaunchButton from '../../molecules/ValeLaunchButton';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import PagesParamList from '../pages_param_list';

const GoAgainComp: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<PagesParamList>>();
  return (
    <div style={containerStyle}>
      <div>
      <h1 style={headingStyle}>READY TO<br/>GO AGAIN?</h1>
        <ValeLaunchButton
        withArrow={true}
          width={225}
          height={50}
          fontStyle={{fontSize:18}}
          onPress={() => {
            navigation.navigate("order", {screen: "selection"});
          }}
          >
          START NEW ORDER
          </ValeLaunchButton>
            </div>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
    marginTop: 50,
    height: 227,
    backgroundImage: `url("https://storage.googleapis.com/vale_scratch_prod/goagain.jpg")`, /* Path to your image */
    backgroundSize: 'cover', /* Cover the entire div */
    backgroundPosition: 'center', /* Center the image */
    backgroundRepeat: 'no-repeat', /* Prevent the image from repeating */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
};

const backgroundStyle: React.CSSProperties = {

};

const floater: React.CSSProperties = {
    //marginTop: -250
};

const headingStyle: React.CSSProperties = {
  color: '#2C2C2C',
  fontSize: '34px',
  fontWeight: 400,
  textAlign: 'center',
  fontFamily: 'BN Dime Display',
};

export default GoAgainComp;
