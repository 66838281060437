import { Box, Modal, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { ReactSVG } from "react-svg";
import { PageTitle } from "../../atoms/TextStyles";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import { useGoToStartOrder } from "../landing/landing_page_utils";
import { useSessionContext } from "../../../system/SessionProvider";
import { useValeNavigation, useValeStackNavigation } from "../../../nav/nav_utils";
import { NavigationContainerRefWithCurrent, NavigationProp } from "@react-navigation/native";
import PagesParamList from "../pages_param_list";
import { StackNavigationProp } from "@react-navigation/stack";
import ValeParamList from "../pages_param_list";

const MenuItem: React.FC<PropsWithChildren> = (props) => {
  return <Typography marginTop={"24px"} textAlign={'center'} fontSize={42} fontFamily={"BN Dime Display"} lineHeight={'37px'} fontWeight={400} style={{wordWrap: 'break-word'}}>{props.children}</Typography>
}

function checkStatus(user : any) {
  console.log('USER' + JSON.stringify(user));
  return (user) ? <MenuItem>YOUR ACCOUNT</MenuItem> : <MenuItem>SIGN-IN</MenuItem>;
}

interface Props {
  user: any
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
  onMenuClose?: () => void;
}

class MenuPage extends React.Component<Props> {
  //navigation: StackNavigationProp<ValeParamList>
  constructor(props: Props) {
      super(props);
      //this.navigation = props.navigation
    }
  
    render () {
        return <div style={{width: "100%"}}>
                <div id="menu_logo" style={{marginTop: '15px', width: "100%", display: "flex", justifyContent: "center"}}><ReactSVG src="/logo_black.svg"/></div>
                <div style={{marginTop: '40px', color: "#2C2C2C"}}>
                  <div onClick={()=>{
                    this.props.navigationRef?.current?.navigate("order", {screen: "selection"});
                    this.props.onMenuClose?.();
                  }} style={{textDecoration:"none", color:"#2C2C2C"}}><MenuItem>MENU</MenuItem></div>
                  <div onClick={()=>{
                    this.props.navigationRef?.current?.navigate("hours");
                    this.props.onMenuClose?.();
                  }}  style={{textDecoration:"none", color:"#2C2C2C"}}><MenuItem>HOURS</MenuItem></div>
                  <div onClick={()=>{
                    this.props.navigationRef?.current?.navigate("about");
                    this.props.onMenuClose?.();
                  }}  style={{textDecoration:"none", color:"#2C2C2C"}}><MenuItem>ABOUT</MenuItem></div>
                  
                  <div onClick={()=>{
                    this.props.navigationRef?.current?.navigate("contact");
                    this.props.onMenuClose?.();
                  }} style={{textDecoration:"none", color:"#2C2C2C"}}><MenuItem>CONTACT</MenuItem></div>
                  
                  {/* <MenuItem>FAQ</MenuItem> */}
                  <div onClick={()=>{
                    this.props.navigationRef?.current?.navigate("account");
                    this.props.onMenuClose?.();
                  }}  style={{textDecoration:"none", color:"#2C2C2C"}}>{checkStatus(this.props.user)}</div>
                </div> 

                <Box
            style={{
              width: "100%",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 50,
            }}
          >
        
          
            <ValeLaunchButton
            withArrow={true}
                onPress={() => {
                  this.props.navigationRef?.current?.navigate("order", {screen: "selection"});
                  this.props.onMenuClose?.();
                }}
                width={200}
                height={54}
                fontStyle={{fontSize:14}}
                >
                START YOUR ORDER
            </ValeLaunchButton>
          

        </Box>

          <Box 
          style={{
              width: "100%",
              marginTop: 30,
            }}>
            
            <center>
            <a href="https://www.tiktok.com/@valegonow" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}><img src="/images/tt_icon.png" style={{marginRight: 25, width: 54}} /></a>
              <a href="https://www.instagram.com/VALEGONOW" target="_blank" style={{textDecoration:"none", color:"#2C2C2C"}}><img src="/images/ig_icon.png" style={{width: 54}} /></a>
            </center>

          </Box>


       </div>
        
    }
}


export type MenuModalProps = {
    modalIsOpen: boolean;
    onMenuClose?: () => void;
    navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
}

const MenuModal: React.FC<MenuModalProps> = (props) => {
  if ( !props.modalIsOpen ) { return null}

  const session = useSessionContext();
  const user = session?.userData?.userRecord;
  //const navigation = useValeStackNavigation();
  return <div style={{ backgroundColor: "#E30A8F", height: "100%"}}>
    <MenuPage onMenuClose={props.onMenuClose} user={user} navigationRef={props.navigationRef}/>
    <div    style={{
              position: "absolute",
              top: "23px",
              left: "30px",
            }} onClick={() => {
                props.onMenuClose?.();
              }}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.62109" y="0.0927429" width="25.9906" height="2.29329" transform="rotate(45 1.62109 0.0927429)" fill="#2C2C2C"/>
                  <rect x="20" y="1.62158" width="25.9906" height="2.29329" transform="rotate(135 20 1.62158)" fill="#2C2C2C"/>
                </svg>  
    </div>
  </div>
}

export default MenuModal;