//  NativeStackScreenProps<CheckoutStackParamList, "phoneNumberEntry">

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import PhoneNumberEntryPage from "../auth/PhoneNumberEntryPage";
import { CheckoutStackParamList } from "./checkout_param_list";

type Props = NativeStackScreenProps<CheckoutStackParamList, "phoneNumberEntry">;
const CheckoutAuthScreen: React.FC<Props> = (props) => {
  
  return (
    <PhoneNumberEntryPage
      onComplete={async () => {
        props.navigation.push("paymentSelection", {});
      }}
    />
  );
};

export default CheckoutAuthScreen;
