import { useCallback } from "react";
import { useValeStackNavigation } from "../../../nav/nav_utils";
import { useValeContext } from "../../../system/ValeSystem";
import { useSessionContext } from "../../../system/SessionProvider";
import { useCartContext } from "../../../system/CartProvider";
import { Components, CreateOrderRequest } from "../../../vale_common";

export const useSubmitOrder = () => {
  const session = useSessionContext();
  const cart = useCartContext();
  
  return useCallback( async (cardId: string | undefined, programId?: string) => {
    if  ( ! cart?.items ||  cart?.items.length === 0 ) {
      console.warn("no items in cart")
      return
    }
    
    const cartItems: Components.Schemas.CartItem[] = cart?.items?.map((item) => { //Components.Schemas.CreateOrderItem
      return {
        itemTypeId: item.itemTypeId,
        itemTypeVariationId: item.itemTypeVariationId,
        clientItemId: item.clientItemId,
        customerName: item.customerName,
        labelTemplateNumber: item.labelTemplateNumber,
        labelImageId: item.labelImageId,

      }
    })
    
    const req: CreateOrderRequest = {
      cartItems,
      paymentMethodId: cardId,
      orderName: cart?.orderName ?? cart?.defaultOrderName,
      loyalityProgramId: programId,
      couponCode: cart?.promoCode,
    }

    const order = await session?.createOrder?.(req);
    if ( order?.data?.status?.code === "ok" ) {
      cart?.clear();
    } else {
      console.warn("order creation failed")
      return
    }    
    return order
  }, [cart])
}

export const useToPaymentPage = () => {
  const vale = useValeContext();
  const navigation = useValeStackNavigation();
  return useCallback(()=>{
    if ( vale?.firebaseAuth?.currentUser) {
      navigation.navigate("checkout", {screen: "paymentSelection", params: {}});
    } else {
      navigation.navigate("checkout", {screen: "phoneNumberEntry", params: {}});
    }
  }, [vale?.firebaseAuth?.currentUser]) 
}

