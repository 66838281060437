import { FC, createRef, forwardRef } from "react";
import PaymentsInput, { PaymentsInputRefProps } from "./PaymentsInput";
import { Box, Modal } from "@mui/material";
import PromptPage, { promptPageModalComProps } from "../modal/PromptPage";
import { PageTitle } from "../../atoms/TextStyles";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

export type NewCardModalProps = {
    onCloseButtonClicked: () => void;
    onCardAdded?: (cardId: string) => void;
    open: boolean;
}

type NewCardProps = {
    onCloseButtonClicked: () => void;
    onCardAdded?: (cardId: string) => void;
}

const NewCard: FC<NewCardProps> =(props)=> {
    const ref = createRef<PaymentsInputRefProps>()
    return (
        <PromptPage onCloseButtonClicked={props.onCloseButtonClicked}>
            <PageTitle>ADD CARD</PageTitle>
            <div style={{height: "8px"}}/>
            <PaymentsInput ref={ref}/>
            <div style={{display:"flex", marginTop: "24px", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"}}>
                <ValeLaunchButton  fontStyle={{fontSize: 18, fontWeight: 700}}  onPress={async () => {
                    const cardId = await ref.current?.processInput()
                    if ( cardId ) {
                        props.onCardAdded?.(cardId)
                    }
                    }}>SAVE</ValeLaunchButton>
            </div>
            
        </PromptPage>
        )
}

const NewCardModal: FC<NewCardModalProps> = (props) => {
    return <Modal open={props.open} componentsProps={promptPageModalComProps}>
            <Box height={"100%"}><NewCard onCardAdded={props.onCardAdded} onCloseButtonClicked={props.onCloseButtonClicked}/></Box>
        </Modal>;
}

export default NewCardModal;