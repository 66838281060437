import { useCallback } from "react";
import { useValeStackNavigation } from "../../../nav/nav_utils";

export const useGoToStartOrder = () => {
  const navigation = useValeStackNavigation();
  return useCallback(() => {
    navigation.push("order", { screen: "selection" });
  }, [navigation]);
};

export const useGoToHome = () => {
  const navigation = useValeStackNavigation();
  return useCallback(() => {
    navigation.push("home");
  }, [navigation]);
};
