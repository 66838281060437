import { Card, Square } from "@square/web-payments-sdk-types";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { getEnv } from "../../../system/env";
import { cardOptions } from "./PaymentStyle";
import { useSessionContext } from "../../../system/SessionProvider";
import { Typography } from "@mui/material";
import { ToggleOff, ToggleOn } from "../../molecules/toggles";

const env = getEnv();

export type PaymentsInputProps = {
}

export type PaymentsInputRefProps = {
    processInput: () => Promise<string | undefined>;
}

const PaymentsInput = forwardRef<PaymentsInputRefProps, PaymentsInputProps> ((props, ref) => {
    const [card, setCard] = useState<Card>();
    
    const session = useSessionContext();
    const [isDefault, setIsDefault] = useState(true);
    
    useImperativeHandle(ref, ()=> ({
        processInput: async () => {
            if ( !card ) {
                console.warn("No card object to process")
                return
            }
            
            try {
                const result = await card.tokenize();
                if (result.status === 'OK') {
                  console.log(`Payment token is ${result.token}`);
                  if ( !result.token ) {
                    throw new Error("token not provided");
                  }
                  const resp = await session?.paymentAddCard?.(result.token, isDefault);
                  const newCardId = resp?.data.newCardId;
                  console.log(`New card id is ${newCardId}`)
                  return newCardId;
                } else {
                  let errorMessage = `Tokenization failed with status: ${result.status}`;
                  if (result.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                      result.errors
                    )}`;
                  }
        
                  throw new Error(errorMessage);
                }
             } catch (e) {
                console.error(e);
            }
        }
    }), [card, isDefault])

    useEffect(() => {
        const load = async () => {
            const payments = window.Square!.payments(env.squareAppId, env.squareDefaultLocationId);

            const card = await payments.card(cardOptions);
            await card.attach('#card-container');
            
            
            setCard(card)
        }
        load();
    }, []);


    const defaultSelector = useMemo(()=>{
      if ( !card ) return null
      return <div style={{display: "flex", alignItems: "center"}}><div onClick={()=>{ setIsDefault(!isDefault) }}>{isDefault ? ToggleOn : ToggleOff}<div style={{
        color: '#2C2C2C',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: '500',
        textAlign: 'center',
      }}>Default payment</div></div></div>
    },[isDefault, card])
    return <div style={{borderRadius: 7, overflow: "hidden", border: '2px #2C2C2C solid', backgroundColor: "#FF00B8", margin:"auto", marginTop: 10, width: "100%", maxWidth: "348px"}}>
    <div style={{display: "flex", alignItems:"center",  justifyContent: "space-between", height: "62px", width:"100%", borderBottom: '2px #2C2C2C solid' }}>
      <div style={{display: "flex", margin: "18px", alignItems: "center"}} >
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12.5" cy="13.0854" r="11.5" fill="white" stroke="#2C2C2C" strokeWidth="2"/>
          <circle cx="12.5" cy="13.0854" r="5.5" fill="#FF00B8"/>
        </svg>
        <Typography color={"white"} fontWeight={600}   fontSize={14} marginLeft={"8px"}>Credit Card</Typography>
      </div>
      <div style={{margin: "18px", display:"flex", gap: "4px"}}>
      <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.585449" width="31" height="21" rx="8" fill="white"/>
        <rect x="0.5" y="1.08545" width="30" height="20" rx="7.5" stroke="#DAD9D9" strokeOpacity="0.2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.41058 14.8114H7.53258L6.12431 9.50378C6.05747 9.25962 5.91554 9.04378 5.70677 8.94205C5.18577 8.6864 4.61166 8.48294 3.98535 8.38033V8.17598H7.01066C7.4282 8.17598 7.74135 8.48294 7.79354 8.83944L8.52423 12.668L10.4013 8.17598H12.2271L9.41058 14.8114ZM13.271 14.8114H11.4973L12.9578 8.17598H14.7314L13.271 14.8114ZM17.026 10.0142C17.0782 9.65682 17.3914 9.45247 17.7567 9.45247C18.3308 9.40117 18.9562 9.50378 19.4782 9.75855L19.7913 8.32991C19.2694 8.12556 18.6953 8.02295 18.1743 8.02295C16.4528 8.02295 15.2002 8.94206 15.2002 10.2177C15.2002 11.1881 16.0875 11.6976 16.7138 12.0046C17.3914 12.3106 17.6524 12.515 17.6002 12.8211C17.6002 13.2802 17.0782 13.4845 16.5572 13.4845C15.9309 13.4845 15.3046 13.3315 14.7314 13.0758L14.4183 14.5053C15.0446 14.7601 15.7222 14.8627 16.3485 14.8627C18.2787 14.9132 19.4782 13.9949 19.4782 12.6167C19.4782 10.8811 17.026 10.7794 17.026 10.0142ZM25.6854 14.8114L24.2771 8.17598H22.7644C22.4513 8.17598 22.1381 8.38033 22.0337 8.6864L19.426 14.8114H21.2518L21.6162 13.8419H23.8595L24.0683 14.8114H25.6854ZM23.0254 9.96289L23.5464 12.4637H22.0859L23.0254 9.96289Z" fill="#172B85"/>
      </svg>
      <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.585449" width="31" height="21.5652" rx="8" fill="white"/>
        <rect x="0.5" y="1.08545" width="30" height="20.5652" rx="7.5" stroke="#DAD9D9" strokeOpacity="0.2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7211 16.0018C14.6657 16.9258 13.2967 17.4836 11.8007 17.4836C8.46279 17.4836 5.75684 14.7065 5.75684 11.2809C5.75684 7.85524 8.46279 5.0782 11.8007 5.0782C13.2967 5.0782 14.6657 5.63598 15.7211 6.55994C16.7765 5.63598 18.1455 5.0782 19.6415 5.0782C22.9795 5.0782 25.6854 7.85524 25.6854 11.2809C25.6854 14.7065 22.9795 17.4836 19.6415 17.4836C18.1455 17.4836 16.7765 16.9258 15.7211 16.0018Z" fill="#ED0006"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7207 16.0018C17.0202 14.8641 17.8442 13.1713 17.8442 11.2809C17.8442 9.3905 17.0202 7.69762 15.7207 6.55994C16.7761 5.63598 18.1451 5.0782 19.6411 5.0782C22.979 5.0782 25.685 7.85524 25.685 11.2809C25.685 14.7065 22.979 17.4836 19.6411 17.4836C18.1451 17.4836 16.7761 16.9258 15.7207 16.0018Z" fill="#F9A000"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7212 6.55997C17.0207 7.69765 17.8447 9.39052 17.8447 11.2809C17.8447 13.1713 17.0207 14.8641 15.7212 16.0018C14.4217 14.8641 13.5977 13.1713 13.5977 11.2809C13.5977 9.39052 14.4217 7.69765 15.7212 6.55997Z" fill="#FF5E00"/>
      </svg>
      <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.585449" width="31" height="21" rx="8" fill="#1F72CD"/>
        <rect x="0.5" y="1.08545" width="30" height="20" rx="7.5" stroke="#DAD9D9" strokeOpacity="0.2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.55765 8.02295L2.65723 14.3638H6.12944L6.55989 13.3528H7.54382L7.97427 14.3638H11.7962V13.5922L12.1368 14.3638H14.1138L14.4543 13.5759V14.3638H22.4029L23.3694 13.3791L24.2744 14.3638L28.357 14.372L25.4474 11.2111L28.357 8.02295H24.3377L23.3969 8.9895L22.5204 8.02295H13.8734L13.1308 9.65959L12.3709 8.02295H8.90594V8.76832L8.52049 8.02295H5.55765ZM17.4272 8.92337H21.9917L23.3877 10.4131L24.8288 8.92337H26.2248L24.1037 11.2102L26.2248 13.4707H24.7655L23.3694 11.9636L21.921 13.4707H17.4272V8.92337ZM18.5544 10.696V9.86544V9.86465H21.4025L22.6452 11.193L21.3474 12.5286H18.5544V11.6218H21.0445V10.696H18.5544ZM6.22951 8.92337H7.92203L9.84588 13.2232V8.92337H11.7L13.1859 12.0063L14.5554 8.92337H16.4002V13.4734H15.2777L15.2685 9.90802L13.632 13.4734H12.6278L10.9821 9.90802V13.4734H8.6728L8.235 12.4533H5.86972L5.43283 13.4725H4.19552L6.22951 8.92337ZM6.27359 11.5103L7.05286 9.69321L7.83122 11.5103H6.27359Z" fill="white"/>
      </svg>
      </div>
    </div>
    <div style={{backgroundColor: "#F87BD3", minHeight: "100px", padding: "16px"}}>
      <div id="payment-form">
            <div id="payment-status-container"></div>
            <div id="card-container"></div>
            {defaultSelector}
        </div>
    </div>  
    </div>
})

export default PaymentsInput;