import { FC, useMemo } from "react";
import { useSessionContext } from "../../../system/SessionProvider";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
import * as capitalize from "capitalize";
import { useCartContext } from "../../../system/CartProvider";

const formatPlural = (count: number, singular: string, plural: string) => {
    return `${count === 1 ? singular : plural}`;
  };

type PaymentCreditsProps = {
    placingOrder: boolean
    onPaymentProcess:(paymentId: string | undefined, programId?: string) => Promise<void>
} 

const PaymentCredits: FC<PaymentCreditsProps> = (props) => {
    const session = useSessionContext();
    const cart = useCartContext();
    const bonkerPoints = session?.userData?.userDoc?.squareLoyaltyBalance //loyalityInfo?.bonkerPoints
    const bonkerProgramId = session?.userData?.userDoc?.squareLoyalityProgramId
    const name = useMemo( ()=> {
        const displayName = session?.userData?.userRecord?.displayName
        return capitalize.words(displayName?.split(" ")?.[0] ?? "")
    }, [session?.userData?.userRecord?.displayName])    
    if ( !bonkerPoints) return null

    const cartToLarge = !!cart?.items?.length && cart?.items?.length > 1

    const cartToLargeBox = cartToLarge ? <div style={{margin: 'auto', padding: 8, color: "#E30A8F", backgroundColor: "white", borderColor: "#E30A8F", maxWidth: 200, marginTop : 16, fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: '500', borderRadius: 7, borderWidth: "4px", borderStyle: "solid",}}>Credits can only be applied on orders with single item 😞</div> : null
    
    return (
        
        <div style={{margin: 'auto', textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 16, width: 345, border: '2px #2C2C2C solid', borderRadius: 7, backgroundColor: "#EBE0FF"}}>
            <div style={{
                marginTop: 16,
                color: '#2C2C2C',
fontSize: 26,
fontFamily: 'BN Dime Display',
fontWeight: '400'}}>DRINK CREDITS</div>
            <div style={{color: '#4712B1',
            marginTop: 16,
fontSize: 16,
fontFamily: 'Inter',
fontWeight: '600'}}>{name}, you have <span style={{
    color: '#E30A8F',
fontSize: 18,
fontFamily: 'Inter',
fontWeight: '700'
}}>{bonkerPoints}</span>{formatPlural(bonkerPoints || 0, " freeby", " freebies")} 🤩</div>
                
            {cartToLargeBox}
            <div style={{margin: 'auto', marginTop : 16, marginBottom: 16}}>
            <ValeLaunchButton disabled={cartToLarge} onPress={() => {
                props.onPaymentProcess(undefined, bonkerProgramId!)
            }} width={139}>APPLY CREDIT</ValeLaunchButton>
            </div>
        </div>
    );
}

export default PaymentCredits