import { CardOptions } from "@square/web-payments-sdk-types";

export const cardOptions: CardOptions = {
    style: {
        'input::placeholder': {
            color: '#999999',
        },
        "input": {
            color: '#EE72F1',
            fontSize: "14px",
            //fontFamily: 'Inter',
            fontWeight: '400',
        },
        ".input-container": {
            borderRadius: "7px",
            borderWidth: "2px",
            borderColor: "#2C2C2C",
        },
        '.message-text': {
            color: '#FF00B8',
          },
        '.message-icon': {
        color: '#FF00B8',
        },
    }
}