import React, { useEffect } from "react";
import Page from "../../templates/Page";
import { PageTitle } from "../../atoms/TextStyles";
import { useValeContext } from "../../../system/ValeSystem";
import _ from "lodash";

import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import PagesParamList from "../pages_param_list";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNavigation, useRoute } from "@react-navigation/native";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

import Confetti from "react-confetti";
import {
  useGetActiveOrders,
  useGetCompletedOrders,
} from "../../hooks/orderHooks";
import { HomePageTileProps } from "./molecules/home_page_tile_types";
import { useGoToCheckoutFlow } from "../checkout/checkout_nav_utils";
import { useTimeToRender } from "../../hooks/perfHooks";
import { useNoSessionContext } from "../../../system/NoSessionProvider";
import { useSessionContext } from "../../../system/SessionProvider";
import { Box, Typography } from "@mui/material";
import Footer from "../../molecules/footer";
import GoAgainComp from "./GoAgainComp";
import OrderReadyHeader from "./OrderReadyHeader";
import PlacingOrderHeader from "./PlacingOrderHeader";

type Props = NativeStackScreenProps<PagesParamList, "home">;
const HomePage: React.FC<Props> = (props) => {
  const session = useSessionContext();
  const vale = useValeContext();
  const activeOrders = useGetActiveOrders();
  const completedOrders = useGetCompletedOrders();
  const noSessionInfo = useNoSessionContext()!;
  const navigation = useNavigation<StackNavigationProp<PagesParamList>>();
  const goToCheckoutFlow = useGoToCheckoutFlow();
  const user = session?.userData?.userRecord;

  useEffect(() => {
    gtag('event', 'screen_view', {
      'screen_name': 'HomePage'
    });
  },[]);

  const tileProps: HomePageTileProps = {
    user,
    activeOrders,
    noSessionInfo,
    navigation,
    goToCheckoutFlow,
    completedOrders,
  };


  function goToInstagram() {
    window.open('https://www.instagram.com/valegonow', '_blank');
  }

  const header = (activeOrders?.length || 0) > 0 ? <PlacingOrderHeader orders={activeOrders}/> : (completedOrders?.length || 0) > 0 ? <OrderReadyHeader orders={completedOrders}/> : null

  useTimeToRender(activeOrders, "Home Page Body : Active Orders");
  useTimeToRender(completedOrders, "Home Page Body : Completed Orders");

  return (
    <Page requireAuth={'login'} {...props}>
      {header}
          <Box
            width="85%"
            border="2px solid black"
            style={{margin: "auto", marginTop: 40}}
          >

            <video autoPlay playsInline loop muted style={{marginBottom:10}} src="https://storage.googleapis.com/vale_scratch_prod/oj_vid.mp4" width="100%"/>

            <Typography style={{textAlign: "center", fontFamily:"BN Dime Display", fontSize:26, color: "#2C2C2C"}}>KEEP UP WITH VALE</Typography>
            <Typography style={{marginBottom: 20, textAlign: "center", fontFamily:"Inter",color:"white", fontWeight:500 }}>See what's new, where we'll be, and<br/>all the fun stuff in between 😎</Typography>
          
            <center><ValeLaunchButton
              width={165}
              height={50}
              fontStyle={{fontSize:18}}
              onPress={goToInstagram}
            >
              FOLLOW US
            </ValeLaunchButton></center>
              
            <div style={{height: 30, width: "100%"}}>&nbsp;</div>

          </Box>
      

          <GoAgainComp />
          
          
          <Footer />

    </Page>
  );
};

export default HomePage;
