import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { Components, OrderInfo } from "../../vale_common";
import ItemInfoSummary from "../pages/home/ItemInfoSummary";
import { useSessionContext } from "../../system/SessionProvider";
import { componentsWithDividers } from "../utils/uiUtils";
import { amountToString } from "../utils/stringConversion";
import { groupBy } from "lodash";

const RECEIPT_WIDTH = 310;
const RECEIPT_STATUS_WIDTH = 228;

type ValeOrderReceiptViewProps = {
    order?: Components.Schemas.Order
}

//Order Status Map to String 
const orderStatusMap: Record<string, string> = {
  COMPLETED: "✅ ORDER COMPLETED",
  PREPARED: "✅ ORDER COMPLETED",
  PROPOSED: "🧑‍🏭️ ORDER IN PROGRESS",
  RESERVED: "🧑‍🏭️ ORDER IN PROGRESS",
};

function itemsByTypeWithMenuInfo (items: Components.Schemas.OrderItem[] | undefined, defaultName: string | undefined) {
  if ( !items) return [];

  //group by item type ( this needs to move to menuItemName but we need to do a DB migration)
  const itemGroups = groupBy(items, "name")
  const itemIds = Object.keys(itemGroups).sort()
  
  const ret = itemIds.map((itemId) => {
      
      const lineItems = itemGroups[itemId]
      const firstLineItem = lineItems[0]
      //add it up 

      return {
        itemName: firstLineItem.name, //needs to move to menuItemName
        pricePerItemCents: firstLineItem.price?.amount,
        customerNames : lineItems.map((lineItem) => lineItem.customerName ?? defaultName ?? "")
      }
  })
  return ret
}

export const ValeOrderReceiptView: FC<ValeOrderReceiptViewProps> = (props) => {
  if ( !props.order ) { return null }

  const session = useSessionContext();

  const defaultName = session?.userData?.userRecord?.displayName //user?.name ?? "Guest"
  
  const lineItems = itemsByTypeWithMenuInfo(props.order.lineItems, defaultName)
    const itemInfoSummaries = lineItems?.map((lineItem) => {
      
      return <ItemInfoSummary
          key={lineItem.itemName}
          customerNames={lineItem.customerNames}
          pricePerItemCents={lineItem.pricePerItemCents!}
          itemName={lineItem.itemName}
          />
    })
    const itemInfoSummariesDivided = componentsWithDividers(itemInfoSummaries, () => <div style={divider} />);

    let formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    let formattedDate = formatter.format(new Date(props.order.createdAt!));
    const status = orderStatusMap[props.order.fullfillmentState!]
    console.log("props.order.fullfillmentState : ", props.order.fullfillmentState)
    const totalCents = props.order.totalMoney?.amount
    return (
        <div style={{maxWidth: `${RECEIPT_WIDTH}px`}}>
          <Box style={conatiner}> 
                <div style={statusContainer}>
                  <div style={{fontWeight:800, fontSize: 18, paddingTop: 10}}>{status}</div>
                  <div style={{fontWeight:500}}>{formattedDate}</div>
                </div>

                {itemInfoSummariesDivided}
     
          <div style={summaryDivider} />
          <Box style={summaryContainer}>
            <span>Total paid</span>
            <span>{amountToString(totalCents)}</span>
          </Box>
          </Box>
          <center>
            <img src="https://storage.googleapis.com/vale_scratch_prod/bottomripped.png" width="100%" />
          </center>
        </div>
    );
};

export type ValeOrderReceiptProps = {
    orderId?: string;
    order?: Components.Schemas.Order;
};

const ValeOrderReceipt: FC<ValeOrderReceiptProps> = (props) => {
  const session = useSessionContext();
  const order = props.order ?? session?.orderMap?.get(props.orderId!);

  return <ValeOrderReceiptView order={order} />;
}

export default ValeOrderReceipt;

const conatiner: React.CSSProperties = {
  backgroundColor: "#EBE0FF",
  borderTop: '2px solid',
  borderLeft: '2px solid',
  borderRight: '2px solid',
  borderTopLeftRadius: 7,
  borderTopRightRadius: 7,
  margin: "auto",
  overflow: "hidden"
}

const statusContainer: React.CSSProperties = {
  textAlign:"center",
  fontFamily:"Inter",
  color: "#4712B1",
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  backgroundColor: "#DECDFF",
  width:`${RECEIPT_STATUS_WIDTH}px`,
  height:60,
  margin:"auto"
}

const divider: React.CSSProperties = {
  width: "90%",
  height: 2,
  backgroundColor: "#DECDFF",
  margin: "auto",
};

const summaryDivider: React.CSSProperties = {
  width: "90%",
  height: 4,
  backgroundColor: "#DECDFF",
  margin: "auto",
};

const summaryContainer: React.CSSProperties = {
  display: "flex",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "12px",
  justifyContent: "space-between",
  color: '#4712B1',
  fontSize: 16,
  fontFamily: 'Inter',
  fontWeight: '700',
};
